import { useEffect } from "react";
import { motion } from "framer-motion";
import { importAll } from "utils/importImages";
import Header from "views/Home/Header";
import CookiesSL from "CookiesSL";
import CookiesHR from "CookiesHR";
import CookiesRS from "CookiesRS";

// Styles
import "./assets/styles/style.scss";

function Cookies({ currentLanguage }) {
  const images = importAll(
    require.context("./assets/images/_lunaai", false, /\.(png|jpe?g|svg)$/)
  );

  useEffect(() => {
    document.body.classList.add("subpage");
    return () => {
      document.body.classList.remove("subpage");
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}>
      <Header images={images} />
      {currentLanguage === "sl" && <CookiesSL />}
      {currentLanguage === "hr" && <CookiesHR />}
      {currentLanguage === "rs" && <CookiesRS />}
    </motion.div>
  );
}

export default Cookies;
