import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Header from "./Header";

const Testimonials = ({ images, trackEvent }) => {
  const { t } = useTranslation();

  return (
    <div className="container narrow my-6 text-center">
      <div className="testimonials mt-5">
        <div className="headline">
          <h2>{t("TestimonialsH")}</h2>
          <p>{t("TestimonialsP")}</p>
        </div>

        <div className="row g-5 pt-4 mb-4">
          <div className="col-sm-4 text-start item">
            <div className="heading d-flex justify-content-center align-items-center">
              <img
                style={{ height: "70px" }}
                src={images["icon-pustolovska.svg"]}
              />
              <h3 className="mb-0 ps-3">{t("Testimonials1H")}</h3>
            </div>
            <div className="content mt-2">
              <p>{t("Testimonials1P")}</p>
            </div>
            <Link to={t("Testimonials1L")} className="cta cta__arrow">
              {t("Readmore")}
            </Link>
          </div>

          <div className="col-sm-4 text-start item">
            <div className="heading d-flex justify-content-center align-items-center">
              <img
                style={{ height: "70px" }}
                src={images["icon-pravljica.svg"]}
              />
              <h3 className="mb-0 ps-3">{t("Testimonials2H")}</h3>
            </div>
            <div className="content mt-2">
              <p>{t("Testimonials2P")}</p>
            </div>
            <Link to={t("Testimonials2L")} className="cta cta__arrow">
              {t("Readmore")}
            </Link>
          </div>

          <div className="col-sm-4 text-start item">
            <div className="heading d-flex justify-content-center align-items-center">
              <img
                style={{ height: "70px" }}
                src={images["icon-pustolovska.svg"]}
              />
              <h3 className="mb-0 ps-3">{t("Testimonials3H")}</h3>
            </div>
            <div className="content mt-2">
              <p>{t("Testimonials3P")}</p>
            </div>
            <Link to={t("Testimonials3L")} className="cta cta__arrow">
              {t("Readmore")}
            </Link>
          </div>
        </div>

        <a href="#ustvari">
          <button
            onClick={() =>
              trackEvent(
                "user_interaction",
                "click",
                "jumpto_create",
                "stories"
              )
            }
            className="btn btn-yellow mt-3 py-3 px-4 text-uppercase text-white">
            {t("HeroCTA")}
          </button>
        </a>
      </div>
    </div>
  );
};

export default Testimonials;
