function LegalSL() {
  return (
    <div className="hero hero__secondary position-relative bg-white px-2 px-sm-0">
      <div className="container narrow bg-white rounded-5 p-md-5">
        <h1 className="mb-3">Pravila zasebnosti</h1>
        <div className="legal__text-block">
          <p className="ng-star-inserted">
            Atlantic&nbsp;Droga Kolinska d.o.o.&nbsp;&nbsp;(v nadaljevanju:
            Družba) je del poslovnega sistema Atlantic Grupe, v okviru katerega
            so vzpostavljeni in uporabljeni visoki standardi poslovnega
            upravljanja in transparentnega poslovanja, kar pomeni odgovorno,
            zakonito in etično utemeljeno obna&scaron;anje vseh pravnih
            subjektov Atlantic Grupe, ki hkrati vključujejo spo&scaron;tovanje
            zasebnosti in varstvo osebnih podatkov.
          </p>
          <p className="ng-star-inserted">
            Ob upo&scaron;tevanju omenjenih standardov, Družba kot vodja
            obdelave v okviru poslovnega sistema Atlantic Grupe, zbira in
            obdeluje osebne podatke zakonito, po&scaron;teno in transparentno, v
            skladu z veljavnimi predpisi ter izvaja ustrezne tehnične in
            organizacijske ukrepe zaradi za&scaron;čite osebnih podatkov pred
            nezakonitim dostopom in zlorabo.
          </p>
          <p className="ng-star-inserted">
            Družba se zaveda, da je varstvo osebnih podatkov pomembno tako
            na&scaron;im uporabnikom, kakor tudi drugim posameznikom. Prav
            zaradi tega vas želimo obvestiti, katere osebne podatke zbiramo,
            kako z njimi upravljamo in kako skrbimo za njihovo varstvo.
          </p>
        </div>
        <div className="legal__text-block">
          <div>
            <div className="title title--h3 text--primary">
              <h3>Namen, obseg in pravni temelj obdelave</h3>
            </div>
          </div>
          <p>
            Osebni podatki se zbirajo za posebne, izrecne in zakonite namene, v
            nujnem obsegu in se obdelujejo na način, ki je skladen s temi
            nameni.
          </p>
        </div>
        <div className="legal__text-block">
          <div>
            <div className="title title--h3 text--primary">
              <h3>
                Družba bo osebne podatke zbirala in obdelovala samo v primerih,
                ko je to s predpisi dovoljeno:
              </h3>
            </div>
          </div>
          <ul>
            <li className="ng-star-inserted">
              <p>obdelava je potrebna zaradi izpolnitve zakonske obveznosti</p>
            </li>
            <li className="ng-star-inserted">
              <p>
                ko je posameznik privolil v obdelavo svojih osebnih podatkov
              </p>
            </li>
            <li className="ng-star-inserted">
              <p>
                obdelava je potrebna za izvajanje pogodbe, katere pogodbena
                stranka je posameznik, na katerega se nana&scaron;ajo osebni
                podatki, ali za izvajanje ukrepov na zahtevo takega posameznika
                pred sklenitvijo pogodbe
              </p>
            </li>
            <li className="ng-star-inserted">
              <p>
                obdelava je potrebna za za&scaron;čito življenjskih interesov
                posameznika, na katerega se nana&scaron;ajo osebni podatki, ali
                druge fizične osebe;
              </p>
            </li>
            <li className="ng-star-inserted">
              <p>
                obdelava je potrebna za zakonite interese Družbe ali povezanih
                družb.
              </p>
            </li>
          </ul>
        </div>
        <div className="legal__text-block">
          <div>
            <div className="title title--h3 text--primary">
              <h3>Zbiranje in uporaba osebnih podatkov</h3>
            </div>
          </div>
          <p className="ng-star-inserted">
            Osebni podatki so informacije, ki vas neposredno ali posredno
            identificirajo. To so na primer va&scaron;e ime in priimek,
            elektronski naslov, telefonska &scaron;tevilka, IP naslov ali
            po&scaron;tni naslov.
          </p>
          <p className="ng-star-inserted">
            Družba ne zbira va&scaron;ih osebnih podatkov, razen v primeru, ko
            vi izrecno predložite tovrstne podatke (na primer v poizvedbi za
            določenim izdelkom ali storitvijo, sklenitvijo pogodbe ali zahtevkom
            za določena postopanja pred sklenitvijo pogodbe, z vključitvijo v
            programe zvestobe, ob prijavi na elektronske novice, sodelovanju v
            anketah, razpisih in nagradnih igrah, naročanju vzorcev ali
            bro&scaron;ur, pro&scaron;njah za donacije in ob zahtevanju
            določenih informacij, predajo življenjepisa ali odprte ponudbe ali
            ob prijavi na razpise za prosta delovna mesta, kakor tudi z
            zaposlitvijo v na&scaron;i Družbi&hellip;).
          </p>
          <p className="ng-star-inserted">
            Va&scaron;e osebne podatke zbiramo, shranjujemo, uporabljamo ali
            prena&scaron;amo v obsegu &ndash; vsebinskem in časovnem &ndash; ki
            je potreben v določenem primeru, na primer, da odgovorimo na
            va&scaron;a vpra&scaron;anja, izpolnimo va&scaron;e zahteve, vas
            obvestimo o na&scaron;ih izdelkih ali rezultatih razpisa ali
            nagradne igre, izpolnjujemo pogodbeno obveznost ali nujna postopanja
            pred sklenitvijo pogodbe, ali izpolnjujemo druge na&scaron;e
            zakonske in pravne obveznosti. Zaradi teh razlogov mora Družba
            osebne podatke včasih prenesti drugim družbam v Atlantic Grupi in
            povezanim družbam ali zunanjim ponudnikom storitev v nadaljnjo
            obdelavo. Ponudniki storitev so lahko angažirani na primer v
            kontekstu storitev klicnega centra, distribucije blaga ali
            ogla&scaron;evalskega materiala ali za organizacijo in izvedbo
            nagradnih iger. Družba od teh strani zahteva, da sprejmejo veljavne
            pravne predpise, ravnajo v skladu z na&scaron;imi navodili in da
            upo&scaron;tevajo na&scaron;a interna pravila o varstvu osebnih
            podatkov, kakor tudi, da sprejmejo ustrezne organizacijske in
            tehnične ukrepe varovanja. Zaradi teh razlogov bodo morali
            va&scaron;i osebni podatki biti preneseni tudi v druge države EU ali
            države izven EU, vendar bo Družba pri tovrstnem prenosu poskrbela,
            da bo ta prenos povsem skladen z zakonom in da bo prenos opravljen z
            vsemi razpoložljivimi in primernimi ukrepi varstva osebnih podatkov.
          </p>
          <p className="ng-star-inserted">
            Družba jamči, da va&scaron;ih osebnih podatkov ne bo nikomur
            prodajala, da va&scaron;ih osebnih podatkov ne bo posredovala ali
            posojala tretji strani, če to ni potrebno za izpolnitev določenega
            namena, za katerega ste nam osebne podatke dali, prav tako jamči, da
            va&scaron;ih osebnih podatkov ne bo dala kateremu koli
            nepoobla&scaron;čenemu prejemniku.
          </p>
          <p className="ng-star-inserted">
            Obstaja možnost, da bomo morali va&scaron;e osebne podatke dostaviti
            pristojnemu organu javne oblasti, v primeru, ko to zahteva zakon ali
            drugi zavezujoči predpis.
          </p>
          <p className="ng-star-inserted">
            Zbrani podatki se bodo hranili tako dolgo, kolikor je to potrebno za
            namen, za katerega so zbrani, oziroma do roka določenega v veljavnih
            predpisih.
          </p>
        </div>
        <div className="legal__text-block">
          <div>
            <div className="title title--h3 text--primary">
              <h3>
                Uporaba osebnih podatkov za namene ogla&scaron;evanja in trženja
              </h3>
            </div>
          </div>
          <p className="ng-star-inserted">
            Ko se va&scaron;i osebni podatki uporabljajo za namene
            ogla&scaron;evanja in trženja skladno z veljavnimi zakonskimi
            predpisi s področja varstva osebnih podatkov, bodo va&scaron;i
            podatki shranjeni in uporabljeni za te namene, na primer za
            po&scaron;iljanje elektronskih obvestil, vzorcev izdelkov, vabil na
            sodelovanje na natečajih in v nagradnih igrah po elektronski
            po&scaron;ti ali po po&scaron;ti ali po drugem komunikacijskem
            kanalu, ki ste ga sprejeli.
          </p>
          <p className="ng-star-inserted">
            Če se bodo va&scaron;i osebni podatki uporabljali za namen
            po&scaron;iljanja individualnih (vam) prilagojenih oglasov in
            promocij zaradi ustvarjanja in vzdrževanja uporabni&scaron;kega
            profila, boste o tem v naprej obve&scaron;čeni, torej &scaron;e pred
            po&scaron;iljanjem tovrstnih oglasov in promocij, pred tovrstno
            obdelavo osebnih podatkov pa vas bomo vsekakor zaprosili posebej,
            izrecno in informirano za privolitev.
          </p>
          <p className="ng-star-inserted">
            Va&scaron;e podatke bomo prav tako uporabili, če bomo želeli
            analizirati in izbolj&scaron;ati učinkovitost na&scaron;ih spletnih
            storitev, ogla&scaron;evanja, trženja, tržnih raziskav in prodajnih
            prizadevanj v anketah ali raznih drugih vpra&scaron;alnikih, ali na
            način, ki bo zagotavljal, da se va&scaron;i osebni podatki ne
            navedejo z imenom oziroma, da se ne morejo povezati s konkretno
            osebo.
          </p>
        </div>
        <div className="legal__text-block">
          <div>
            <div className="title title--h3 text--primary">
              <h3>Uveljavljanje pravice posameznika</h3>
            </div>
          </div>
          <p className="ng-star-inserted">
            V katerem koli trenutku lahko od nas zahtevate informacijo o svojih
            osebnih podatkih, ki jih obdelujemo, ali zahtevate njihov popravek,
            lahko prekličete njihovo uporabo, ali izbris svojih osebnih
            podatkov, ali preklic privolitve za vse ali samo za nekatere namene
            (razen ko to ni mogoče, na primer, ko gre za obdelavo podatkov za
            statistične namene, za katere osebni podatki nikakor ne omogočajo
            identifikacije), oziroma prepoveste prenos podatkov drugemu vodji
            obdelave pod zakonskimi pogoji, prav tako pa lahko odjavite
            sprejemanje na&scaron;ih informativnih in promocijskih materialov.
          </p>
          <p className="ng-star-inserted">
            Prav tako lahko vložite ugovor na uporabo svojih osebnih podatkov v
            situacijah, ki so utemeljene na obstoju na&scaron;ega legitimnega
            interesa, &scaron;e posebej, ko jih uporabljamo za potrebe
            neposrednega trženja.
          </p>
          <p className="ng-star-inserted">
            Z na&scaron;e strani bomo v največji možni in razumni meri skrbeli
            za to, da bodo osebni podatki, ki ste nam jih dostavili na
            razpolaganje, pravilno evidentirani, hkrati pa je odgovornost za
            točnost tudi na va&scaron;i strani, zato vas prosimo, da podatke
            redno in pravočasno posodabljate, oziroma da nam dostavite zahtevek
            za vpis spremembe ali za popravek.
          </p>
          <p className="ng-star-inserted">
            Za uveljavitev vseh svojih pravic in obveznosti glede uporabe
            osebnih podatkov se lahko obrnete na elektronski naslov ali naslov
            sedeža Družbe, ki ju boste na&scaron;li na spletni strani, ali na
            elektronski naslov: dpo@atlanticgrupa.com, ali po navodilu na
            dostavljenih promocijskih materialih. Ob vzpostavitvi kontakta in
            prejemu navedenih zahtev, bomo vložili razumne napore, da potrdimo
            va&scaron;o identiteto in preprečimo neavtorizirano obdelavo osebnih
            podatkov.
          </p>
          <p className="ng-star-inserted">
            Po prejemu va&scaron;ih zahtev bomo zahtevano aktivnost izvedli v
            primernem roku, vendar najkasneje v roku 30 dni od prejema ter vas o
            tem po potrebi obvestili, vse skladno z zakonom.
          </p>
        </div>
        <div className="legal__text-block">
          <div>
            <div className="title title--h3 text--primary">
              <h3>Otroci</h3>
            </div>
          </div>
          <p className="ng-star-inserted">
            Praviloma podatke o otrocih ne zbiramo. Če ugotovimo, da so nam le
            ti posredovani brez privolitve star&scaron;ev ali zakonitih
            zastopnikov, jih bomo izbrisali v najkraj&scaron;em možnem času. Pri
            tem bomo upo&scaron;tevali smernice, ki smo jih prejeli od vas kot
            star&scaron;a ali zakonitega skrbnika.
          </p>
          <p className="ng-star-inserted">
            V kolikor bi vendarle za neke namene zbirali podatke o otrocih,
            starih toliko, da je skladno z veljavnimi zakoni za obdelavo
            njihovih osebnih podatkov potrebna privolitev star&scaron;ev, bomo o
            tem objavili ustrezno informacijo in uporabili razumne ukrepe, da bi
            ugotovili, ali imajo otroci privolitev star&scaron;ev oziroma
            zakonitega skrbnika.
          </p>
        </div>
        <div className="legal__text-block">
          <div>
            <div className="title title--h3 text--primary">
              <h3>Varnost podatkov</h3>
            </div>
          </div>
          <p className="ng-star-inserted">
            Implementirali smo tehnične in organizacijske ukrepe, da bi
            preprečili izgubo va&scaron;ih podatkov, spreminjanje, odtujitev ali
            dostop nepoobla&scaron;čene tretje strani, oziroma kakr&scaron;no
            koli nepoobla&scaron;čeno obdelavo le teh. Dostop k va&scaron;im
            osebnim podatkom je omejen samo na tiste zaposlene, ki so jim te
            informacije potrebne za posredovanje na&scaron;ih storitev oziroma
            za re&scaron;evanje va&scaron;ega vpra&scaron;anja. Nenehno
            izobražujemo na&scaron;e zaposlene o tem, kako pomembna je zaupnost
            podatkov in ohranjanje zasebnosti in varstvo va&scaron;ih podatkov.
            Zbiramo samo tiste podatke, ki so potrebni za določeno obdelavo in
            jih ne hranimo dlje, kot je to potrebno oziroma določeno z
            zakonskimi predpisi.
          </p>
          <p className="ng-star-inserted">
            Na na&scaron;i spletni strani boste lahko občasno na&scaron;li
            povezave na druge spletne strani ali portale, lahko pa vam ponudimo
            tudi značilnosti družbenih omrežij, ki omogočajo delitev osebnih
            podatkov na družbenih omrežjih. Priporočamo, da vsekakor predhodno
            preberete pravila o varstvu osebnih podatkov na teh portalih ali
            družbenih omrežjih ali tretjih osebah, kakor tudi o sprejetih
            ukrepih o varstvu le teh, saj se lahko razlikujejo od teh Pravil in
            na&scaron;ih sprejetih ukrepov, za kar pa mi ne moremo biti
            odgovorni.
          </p>
        </div>
        <div className="legal__text-block">
          <div>
            <div className="title title--h3 text--primary">
              <h3>Omejitev odgovornosti</h3>
            </div>
          </div>
          <p className="ng-star-inserted">
            Čeprav sprejemamo dostopne tehnične, organizacijske in kadrovske
            ukrepe za za&scaron;čito osebnih podatkov pred naključno ali namerno
            zlorabo, uničenjem, izgubo, nepoobla&scaron;čenimi spremembami ali
            dostopom, ne moremo jamčiti, da nekateri osebni podatki, ki jih
            zberemo, kljub določbam teh Pravil o varovanju zasebnosti, ne bodo
            nikoli naključno razkriti.
          </p>
          <p className="ng-star-inserted">
            V največji meri, ki jo dopu&scaron;ča zakon, izključujemo
            odgovornost za &scaron;kodo povzročeno uporabnikom ali tretjim
            osebam z naključnim razkritjem osebnih podatkov.
          </p>
          <p className="ng-star-inserted">
            Ker nimamo nadzora nad osebnimi podatki, ki jih predložite pri
            dostopu do drugih portalov in uporabi le teh ali neposredno tretjim
            osebam (na primer pri sodelovanju v sponzoriranih dejavnostih), v
            največji meri, ki jo dopu&scaron;ča zakon, izključujemo odgovornost
            za &scaron;kodo, ki bi lahko nastala vam ali tretjim osebam zaradi
            posredovanja osebnih podatkov.
          </p>
        </div>
        <div className="legal__text-block">
          <div>
            <div className="title title--h3 text--primary">
              <h3>Posodabljanje in spreminjanje</h3>
            </div>
          </div>
          <p className="ng-star-inserted">
            Pridržujemo si pravico na spreminjanje ali posodabljanje delov teh
            Pravil v vsakem trenutku in brez predhodne najave. Obi&scaron;čite
            občasno na&scaron;o spletno stran, da boste seznanjeni s spremembami
            ali posodobitvijo Pravil. Objavljen bo datum začetka veljavnosti
            aktualne verzije Pravil.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LegalSL;
