import { getLocalStorage } from "utils/getLocalStorage";

const hasAnalytics = getLocalStorage("cookies-analytics");

const trackEvent = (event, type, context, detail) => {
  //GTM
  if (hasAnalytics) {
    window.dataLayer = window.dataLayer || [];

    if (
      typeof window.dataLayer === "object" ||
      typeof window.dataLayer !== "undefined" ||
      window.dataLayer.length > 0 ||
      window.dataLayer !== null
    ) {
      window.dataLayer.push({
        event: event,
        interaction_type: type, //tip interakcije
        interaction_context: context, //vsebina na katero je bil npr. click
        interaction_detail: detail, //po potrebi dodaten label s podrobnostjo izbire
      });
    }
  }
  //Plausible
  window.plausible(`${type}_${context}`, {
    props: {
      interaction_detail: detail,
    },
  });
};

export { trackEvent };
