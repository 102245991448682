function CookiesSL() {
  return (
    <div className="hero hero__secondary position-relative bg-white px-2 px-sm-0">
      <div className="container narrow bg-white rounded-5 p-md-5">
        <section>
          <div>
            <div color="black">
              <div>
                <section>
                  <div>
                    <div color="black">
                      <div>
                        <h1>Kolačići</h1>
                      </div>
                    </div>
                    <div>
                      <p>
                        Minimalnu količinu podataka (kolačići) potrebno je
                        sačuvati na va&scaron;em računaru da bi ova veb-lokacija
                        pravilno funkcionisala i da bi se pobolj&scaron;ale
                        mogućnosti pretraživanja. Vi&scaron;e od 90%
                        veb-lokacija koristi kolačiće, međutim, propisi Evropske
                        unije zahtevaju da se zatraži saglasnost korisnika.
                        Kori&scaron;ćenjem ove veb-lokacije vi dajete saglasnost
                        za kori&scaron;ćenje kolačića. Ukoliko niste saglasni,
                        moguć je pregled sadržaja veb-lokacije, ali neke
                        funkcije možda neće biti pravilno izvr&scaron;ene ili
                        prikazane.
                      </p>
                    </div>
                    <div>
                      <div color="primary">
                        <div>
                          <h5>&Scaron;ta je kolačić?</h5>
                        </div>
                      </div>
                      <p>
                        Kolačić je informacija koju na va&scaron; računar
                        stavlja veb-sajt koji ste posetili. Kolačići obično
                        pohranjuju va&scaron;e postavke za veb-sajt kao
                        &scaron;to su željeni jezik ili adresa. Kada kasnije
                        ponovo otvorite isti veb-sajt, pregledač će poslati
                        nazad kolačiće koji pripadaju tom sajtu. To tom sajtu
                        omogućava da prikaže informacije prilagođene
                        va&scaron;im potrebama.
                      </p>
                      <p>
                        Kolačići mogu da pohrane &scaron;iroku lepezu
                        informacija, uključujući informacije o ličnosti
                        (va&scaron;e ime ili elektronsku adresu). Uprkos svemu,
                        ta informacija može da se sačuva samo ukoliko vi to
                        omogućite &ndash; veb-sajt ne može da dobije pristup
                        informacijama ukoliko ga vi ne odobrite, a osim toga, ne
                        može da pristupi ostalim datotekama na va&scaron;em
                        računaru ili mobilnom uređaju. Vi nećete videti
                        podrazumevane postavke pohranjivanja i slanja kolačića,
                        ali možete da promenite svoje postavke pregledača na
                        način da možete sami da izaberete da li želite da
                        prihvatite ili odbijete zahteve za pohranjivanje
                        kolačića, da se sačuvani kolačići obri&scaron;u prilikom
                        zatvaranja pregledača i slično.
                      </p>
                    </div>
                    <div>
                      <div color="primary">
                        <div>
                          <h5>Kako odbiti kolačiće?</h5>
                        </div>
                      </div>
                      <p>
                        Odbijanjem kolačića odlučujete da li želite da dozvolite
                        pohranjivanje kolačića na svoj računar ili mobilni
                        uređaj. Postavke kolačića mogu da se nadziru i
                        konfiguri&scaron;u u pregledaču. Ukoliko ne prihvatite
                        kolačiće, može da se desi da nećete moći da koristite
                        određene funkcije na veb-sajtu.
                      </p>
                    </div>
                    <div>
                      <div color="primary">
                        <div>
                          <h5>&Scaron;ta su privremeni kolačići?</h5>
                        </div>
                      </div>
                      <p>
                        Privremeni ili sesijski kolačići biće obrisani sa
                        računara po&scaron;to zatvorite pregledač. Pomoću njih
                        veb-sajt pohranjuje privremene podatke kao &scaron;to su
                        proizvodi u kupovnoj korpi.
                      </p>
                    </div>
                    <div>
                      <div color="primary">
                        <div>
                          <h5>&Scaron;ta su trajni kolačići?</h5>
                        </div>
                      </div>
                      <p>
                        Trajni ili sačuvani kolačići ostaju na računaru i posle
                        zatvaranja pregledača. Pomoću njih veb-sajt pohranjuje
                        privremene podatke kao &scaron;to su naziv za prijavu i
                        lozinku kako ne biste morali da se prijavite prilikom
                        svake posete određenom sajtu. Trajni kolačići ostaju na
                        računaru vi&scaron;e dana, meseci ili čak vi&scaron;e
                        godina.
                      </p>
                    </div>
                    <div>
                      <div color="primary">
                        <div>
                          <h5>&Scaron;ta su kolačići prvog lica?</h5>
                        </div>
                      </div>
                      <p>
                        Kolačići prvog lica (sopstveni kolačići) dolaze sa
                        veb-sajta koji korisnik pregleda i mogu da budu trajni
                        ili privremeni. Veb-sajt može pomoću tih kolačića da
                        sačuva podatke koje će ponovo upotrebiti kada ga
                        korisnik naredni put poseti.
                      </p>
                    </div>
                    <div>
                      <div color="primary">
                        <div>
                          <h5>&Scaron;ta su kolačići trećeg lica?</h5>
                        </div>
                      </div>
                      <p>
                        Kolačići trećeg lica (drugi kolačići) dolaze sa drugih,
                        partnerskih veb-sajtova (kao &scaron;to su iskačući
                        prozori ili druge reklame) koje pregledate. Veb-sajt
                        može pomoću tih kolačića da prati kori&scaron;ćenje veba
                        u marketin&scaron;ke svrhe.
                      </p>
                    </div>
                    <div>
                      <div color="primary">
                        <div>
                          <h5>Da li ovaj veb-sajt koristi kolačiće?</h5>
                        </div>
                      </div>
                      <p>
                        Da, sa primarnim ciljem da vam na&scaron; veb-sajt
                        omogući najbolje korisničko iskustvo.
                      </p>
                    </div>
                    <div>
                      <div color="primary">
                        <div>
                          <h5>
                            Kakve kolačiće koristi ovaj veb-sajt i za&scaron;to?
                          </h5>
                        </div>
                      </div>
                      <p>
                        Trajni ili sačuvani kolačići (persistent cookies) imaju
                        obično datum isteka postavljen daleko u budućnost, pa će
                        ostati u va&scaron;em pregledaču dok ne isteknu ili dok
                        ih ručno ne obri&scaron;ete. Mi koristimo trajne
                        kolačiće kako bismo bolje razumeli navike korisnika,
                        čime možemo da unapredimo veb-sajt s obzirom na
                        va&scaron;e navike. Na osnovu podataka prikupljenih na
                        taj način nije moguća identifikacija vas kao korisnika,
                        a prikupljeni podaci se koriste isključivo u statističke
                        svrhe.
                      </p>
                      <p>
                        Privremeni ili sesijski kolačići (session cookies) jesu
                        kolačići koji isteknu (i automatski se bri&scaron;u)
                        kada zatvorite pregledač. Mi koristimo privremene
                        kolačiće kako bismo korisniku obezbedili najbolje
                        iskustvo prilikom upotrebe na&scaron;eg veb-sajta.
                      </p>
                    </div>
                    <div>
                      <div color="primary">
                        <div>
                          <h5>
                            Da li na veb-sajtu postoje kolačići trećeg lica?
                          </h5>
                        </div>
                      </div>
                      <p>
                        Postoji vi&scaron;e spoljnih servisa koji za korisnika
                        čuvaju ograničene kolačiće. Te kolačiće nije instalirao
                        ovaj veb-sajt, ali neki služe za normalan rad određenih
                        funkcija koje olak&scaron;avaju pristup korisnika
                        sadržini. Zato informacije o za&scaron;titi podataka sa
                        kojim ste upoznati ovde važe samo za ovaj veb-sajt, a
                        za&scaron;titu podataka kod spoljnih servisa treba
                        proveriti na njihovim zvaničnim sajtovima.
                      </p>
                    </div>
                    <div>
                      <div color="primary">
                        <div>
                          <h2>Trenutno omogućavamo:</h2>
                        </div>
                      </div>
                      <div>
                        <p>Merenje poseta</p>
                        <p>
                          Ovaj veb-sajt koristi servise za merenje broja
                          korisnika, i to Google Analytics. Na osnovu podataka
                          prikupljenih na taj način nije moguća identifikacija
                          korisnika, a prikupljeni podaci se koriste isključivo
                          u statističke svrhe. Ukoliko želite da sprečite da
                          navedeni servisi za vas sačuvaju kolačiće, možete da
                          ih odbijete za svaki servis putem sledećih veza:
                        </p>
                        <ul>
                          <li>
                            &nbsp;Google Analytics -{" "}
                            <a
                              target="_blank"
                              href="https://tools.google.com/dlpage/gaoptout">
                              https://tools.google.com/dlpage/gaoptout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <div color="primary">
                        <div>
                          <h2>Dodatne informacije o odbijanju kolačića</h2>
                        </div>
                      </div>
                      <p>
                        Trenutno postoji vi&scaron;e stranica za odbijanje
                        memorisanja kolačića za različne servise.
                      </p>
                      <p>
                        Vi&scaron;e o tome možete da saznate na sledećim
                        linkovima:
                      </p>
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="http://www.allaboutcookies.org">
                            http://www.allaboutcookies.org
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="http://www.youronlinechoices.eu/">
                            http://www.youronlinechoices.eu/
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div></div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default CookiesSL;
