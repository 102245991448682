import { useState, useReducer } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocalStorage, setLocalStorage } from "utils/getLocalStorage";

const CookieNotice = ({ showCookieNotice, setShowCookieNotice }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [settings, setSettings] = useReducer(
    (prev, next) => {
      const newSettings = { ...prev, ...next };

      return newSettings;
    },
    {
      cookiesAcceptance: true,
      cookiesAnalytics: getLocalStorage("cookies-analytics"),
      cookiesMarketing: getLocalStorage("cookies-marketing"),
    }
  );

  const { t, i18n } = useTranslation();

  const expandNotice = () => {
    setIsExpanded(!isExpanded);
  };

  const clickHandlerCookies = () => {
    setShowCookieNotice(false);
    setLocalStorage("hasAccepted", true);
    if (settings.cookiesAcceptance) {
      setLocalStorage("cookie-acceptance", true);
    }
    if (settings.cookiesAnalytics) {
      setLocalStorage("cookies-analytics", true);
    } else {
      setLocalStorage("cookies-analytics", false);
    }
    if (settings.cookiesMarketing) {
      setLocalStorage("cookies-marketing", true);
    } else {
      setLocalStorage("cookies-marketing", false);
    }

    // If acccept all
    if (!isExpanded) {
      setLocalStorage("cookies-analytics", true);
      setLocalStorage("cookies-marketing", true);
    }

    window.location.reload();
  };

  return (
    <motion.div
      className="cookie-notice"
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "-100%" }}>
      <div className="container cookie-wrapper">
        <div className="row">
          <div className="col-12 col-md-8 d-flex align-items-center">
            <p className="mb-4 mb-md-0">
              {t("CookieNoticeP")}
              {"\u00A0"}
              <Link to={t("CookieNoticePLinkUrl")}>
                {t("CookieNoticePLinkTitle")}
              </Link>
            </p>
          </div>
          <div className="col-12 col-md-4 text-end">
            <div className="text-center">
              <button
                onClick={() => clickHandlerCookies()}
                className="btn btn-sm btn-yellow text-white px-5 px-md-6 py-3 transform-none ms-1 ms-sm-3 ">
                <svg
                  className="arrow"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 9C0 4.02944 4.02944 0 9 0C13.9705 0 18 4.02943 18 9C18 13.9705 13.9705 18 9 18C4.02943 18 0 13.9705 0 9Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.5 7C4.22389 7 4 7.22392 4 7.5C4 7.77608 4.22389 8 4.5 8C4.77611 8 5 7.77608 5 7.5C5 7.22392 4.77611 7 4.5 7ZM3 7.5C3 6.67168 3.67155 6 4.5 6C5.32845 6 6 6.67168 6 7.5C6 8.32832 5.32845 9 4.5 9C3.67155 9 3 8.32832 3 7.5Z"
                    fill="#F2B000"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.5 13C10.2238 13 10 13.2238 10 13.5C10 13.7762 10.2238 14 10.5 14C10.7762 14 11 13.7762 11 13.5C11 13.2238 10.7762 13 10.5 13ZM9 13.5C9 12.6716 9.67156 12 10.5 12C11.3284 12 12 12.6716 12 13.5C12 14.3284 11.3284 15 10.5 15C9.67156 15 9 14.3284 9 13.5Z"
                    fill="#F2B000"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.76691 3.79289C9.15744 4.18342 9.15744 4.81658 8.76691 5.20711L8.75691 5.21711C8.36639 5.60763 7.73322 5.60763 7.3427 5.21711C6.95217 4.82658 6.95217 4.19342 7.3427 3.80289L7.3527 3.79289C7.74322 3.40237 8.37639 3.40237 8.76691 3.79289Z"
                    fill="#F2B000"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.21711 11.4429C6.60763 11.8334 6.60763 12.4666 6.21711 12.8571L6.20711 12.8671C5.81658 13.2577 5.18342 13.2577 4.79289 12.8671C4.40237 12.4766 4.40237 11.8434 4.79289 11.4529L4.80289 11.4429C5.19342 11.0524 5.82658 11.0524 6.21711 11.4429Z"
                    fill="#F2B000"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.0169 5.49297C13.4074 5.88349 13.4074 6.51666 13.0169 6.90718L13.0069 6.91718C12.6164 7.3077 11.9832 7.3077 11.5927 6.91718C11.2022 6.52666 11.2022 5.89349 11.5927 5.50297L11.6027 5.49297C11.9932 5.10244 12.6264 5.10244 13.0169 5.49297Z"
                    fill="#F2B000"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.867 9.74297C14.2575 10.1335 14.2575 10.7667 13.867 11.1572L13.857 11.1672C13.4665 11.5577 12.8333 11.5577 12.4428 11.1672C12.0523 10.7767 12.0523 10.1435 12.4428 9.75297L12.4528 9.74297C12.8433 9.35244 13.4765 9.35244 13.867 9.74297Z"
                    fill="#F2B000"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.4671 8.04289C10.8576 8.43342 10.8576 9.06658 10.4671 9.45711L10.4571 9.46711C10.0666 9.85763 9.43342 9.85763 9.04289 9.46711C8.65237 9.07658 8.65237 8.44342 9.04289 8.05289L9.05289 8.04289C9.44342 7.65237 10.0766 7.65237 10.4671 8.04289Z"
                    fill="#F2B000"
                  />
                </svg>
                <span className="ms-2">
                  {isExpanded
                    ? t("CookieNoticeButtonAll")
                    : t("CookieNoticeButtonSome")}
                </span>
              </button>

              <button
                className="btn btn-link mt-4 d-flex align-items-center mx-auto"
                onClick={() => expandNotice()}>
                <span className="me-2 lh-1">prilagodite nastavitve</span>
                <svg
                  className={`arrow ${isExpanded && "rotate"}`}
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.009 0H1.99101C1.16863 0 0.6978 0.937427 1.18885 1.59711L5.19784 6.9828C5.59771 7.51999 6.40229 7.51999 6.80216 6.9828L10.8111 1.59711C11.3022 0.937426 10.8314 0 10.009 0Z"
                    fill="#0D2E55"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {isExpanded && (
          <motion.div
            className="row mt-5"
            key={isExpanded}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <div className="col-12 col-md-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="cookiesAcceptance"
                  checked={settings.cookiesAcceptance}
                  onChange={() =>
                    setSettings({
                      cookiesAnalytics: false,
                    })
                  }
                />
                <label
                  className="form-check-label small"
                  htmlFor="cookiesAcceptance">
                  <strong>{t("Cookies1H")}</strong>
                  <br />
                  {t("Cookies1P")}
                </label>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="cookiesAnalytics"
                  checked={settings.cookiesAnalytics}
                  onChange={() =>
                    setSettings({
                      cookiesAnalytics: !settings.cookiesAnalytics,
                    })
                  }
                />
                <label
                  className="form-check-label small"
                  htmlFor="cookiesAnalytics">
                  <strong>{t("Cookies2H")}</strong>
                  <br />
                  {t("Cookies2P")}
                </label>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="cookiesMarketing"
                  checked={settings.cookiesMarketing}
                  onChange={() =>
                    setSettings({
                      cookiesMarketing: !settings.cookiesMarketing,
                    })
                  }
                />
                <label
                  className="form-check-label small"
                  htmlFor="cookiesMarketing">
                  <strong>{t("Cookies3H")}</strong>
                  <br />
                  {t("Cookies3P")}
                </label>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default CookieNotice;
