import { useEffect } from "react";
import { motion } from "framer-motion";
import { importAll } from "utils/importImages";
import Header from "views/Home/Header";

// Styles
import "./assets/styles/style.scss";
import LegalSL from "LegalSL";
import LegalHR from "LegalHR";
import LegalRS from "LegalRS";

function Legal({ currentLanguage }) {
  const images = importAll(
    require.context("./assets/images/_lunaai", false, /\.(png|jpe?g|svg)$/)
  );

  useEffect(() => {
    document.body.classList.add("subpage");

    return () => {
      document.body.classList.remove("subpage");
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}>
      <Header images={images} />
      {currentLanguage === "sl" && <LegalSL />}
      {currentLanguage === "hr" && <LegalHR />}
      {currentLanguage === "rs" && <LegalRS />}
    </motion.div>
  );
}

export default Legal;
