// Reacts & Libs
import { useState, useEffect, useReducer, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setStory } from "./redux/storySlice";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";

import { useTranslation } from "react-i18next";

// Utils
import { importAll } from "./utils/importImages";
import { dataExclude } from "dataExclude";
import { trackEvent } from "views/Home/tracking";

// Components
import Header from "views/Home/Header";
import Hero from "views/Home/Hero";
import Usps from "views/Home/Usps";
import Form from "views/Home/Form";
import Testimonials from "views/Home/Testimonials";
import Howitworks from "views/Home/Howitworks";
import Remember from "views/Home/Remember";
import Dogodivscine from "views/Home/Dogodivscine";
import Loader from "views/Home/Loader";

// Styles
import "./assets/styles/style.scss";

function CardGenerator({ baseDomain }) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledMsg, setDisabledMsg] = useState("");
  const [error, setError] = useState(false);
  const [countResults, setCountResults] = useState(null);

  const [promptVar, updatePromptVar] = useReducer(
    (prev, next) => {
      const newPromptVar = { ...prev, ...next };

      return newPromptVar;
    },
    {
      firstname: "",
      age: "",
      gender: "",
      heroitem: "",
      genre: "",
      lesson: "",
    }
  );

  const recaptchaRef = useRef(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();

  const images = importAll(
    require.context("./assets/images/_lunaai", false, /\.(png|jpe?g|svg)$/)
  );

  const generateText = async () => {
    const isCursedWord = dataExclude.some(
      (val) =>
        promptVar.heroitem.toLowerCase().includes(val.toLowerCase()) ||
        promptVar.firstname.toLowerCase().includes(val.toLowerCase())
    );

    if (
      promptVar.firstname === "" ||
      promptVar.age === "" ||
      promptVar.gender === "" ||
      promptVar.genre === "" ||
      promptVar.heroitem.length > 70 ||
      promptVar.firstname.length > 30
      // || promptVar.lesson === ""
    ) {
      goToStart();
      if (isCursedWord) {
        setDisabledMsg(t("disabledMsg"));
      } else {
        setDisabled(true);
      }
      return;
    }
    const captchaToken =
      localStorage.getItem("captcha") === "success"
        ? "success"
        : await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    setDisabled(false);
    setLoading(true);

    try {
      const res = await axios.post(`${baseDomain}/api`, {
        promptVar,
        captchaToken,
      });

      localStorage.setItem("captcha", "success");
      dispatch(setStory(res.data.jobId));

      setLoading(true);

      trackEvent("user_interaction", "click", "create_story", "main");
      trackEvent("user_interaction", "select", "story", promptVar.genre);
      trackEvent("user_interaction", "select", "moral", promptVar.lesson);

      setTimeout(() => {
        navigate(`/result/${res.data.jobId}`);
      }, 10000);
    } catch (err) {
      console.log("error", err);
      setError(true);
    }
  };

  async function getCountResults() {
    try {
      let data = await axios.get(`${baseDomain}/countResults`);
      console.log(baseDomain);
      setCountResults(data.data);
    } catch (err) {
      console.log(`ERROR: ${err}`);
    }
  }

  const handleChangeAge = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      updatePromptVar({
        age: e.target.value,
      });
    }
  };

  useEffect(() => {
    getCountResults();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}>
      <Header trackEvent={trackEvent} images={images} />
      <Hero
        images={images}
        count={countResults !== null && countResults}
        trackEvent={trackEvent}
      />

      <Usps images={images} />

      <Form
        images={images}
        promptVar={promptVar}
        updatePromptVar={updatePromptVar}
        handleChangeAge={handleChangeAge}
        generateText={generateText}
        loading={loading}
        disabled={disabled}
        disabledMsg={disabledMsg}
        recaptchaRef={recaptchaRef}
      />

      <Testimonials images={images} trackEvent={trackEvent} />
      <Howitworks images={images} trackEvent={trackEvent} />
      <Remember images={images} t={t} />
      <Dogodivscine
        images={images}
        t={t}
        trackEvent={trackEvent}
        currentLanguage={currentLanguage}
      />

      {loading && <Loader images={images} type="fetching" t={t} />}
      {error && (
        <Loader
          images={images}
          type="error"
          error={error}
          setError={setError}
          t={t}
        />
      )}
    </motion.div>
  );
}

function goToStart() {
  // Get the section element by ID
  const section = document.getElementById("form");

  // Scroll to the section using scrollIntoView()
  section.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });

  // Prevent the default link behavior
  return false;
}

export default CardGenerator;
