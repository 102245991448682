import { useEffect } from "react";
import imgMenuClose from "assets/images/_lunaai/hamburger-menu-close.svg";
import { motion } from "framer-motion";
const Loader = ({ images, type, isPreResultError, preResultError, t }) => {
  useEffect(() => {
    document.documentElement.classList.add("noscroll");

    return () => {
      document.documentElement.classList.remove("noscroll");
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      className="backdrop loader d-flex justify-content-left align-items-start align-items-md-center pt-5 pt-md-0 ">
      {type === "error" && (
        <div
          onClick={() => isPreResultError(false)}
          className="close position-absolute end-0 top-0 p-5">
          <img src={imgMenuClose} />
        </div>
      )}

      <div className="container text-start ">
        <div className="col-md-6">
          <svg
            style={{ width: "120px" }}
            version="1.1"
            id="L4"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 0 0"
            xmlSpace="preserve">
            <circle fill="#F2B000" stroke="none" cx="6" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.1"
              />
            </circle>
            <circle fill="#F2B000" stroke="none" cx="26" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.2"
              />
            </circle>
            <circle fill="#F2B000" stroke="none" cx="46" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.3"
              />
            </circle>
          </svg>

          {type === "fetching" && (
            <>
              <h2 className="text-white">{t("LoaderFetchH")}</h2>
              <p className="text-white">{t("LoaderFetchP1")}</p>
              <p className="text-white">{t("LoaderFetchP2")}</p>
              <p className="text-white small">{t("LoaderFetchP3")}</p>
            </>
          )}

          {type === "error" && (
            <>
              <h2 className="text-white">{t("LoaderErrorH")}</h2>
              <p className="text-white">{t("LoaderErrorP1")}</p>
              <p className="text-white">{t("LoaderErrorP2")}</p>
              <button
                onClick={() => isPreResultError(false)}
                className="btn btn-yellow mt-3 py-3 px-4 text-uppercase text-white">
                {t("LoaderErrorBT")}
              </button>
            </>
          )}
        </div>
      </div>
      <img
        className={`bottom-racek  position-absolute  d-xl-block ${
          type === "error" && "bottom-racek__hide"
        }`}
        src={images["bg-racek-loader.png"]}
      />
    </motion.div>
  );
};

export default Loader;
