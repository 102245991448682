function LegalSL() {
  return (
    <div className="hero hero__secondary position-relative bg-white px-2 px-sm-0">
      <div className="container narrow bg-white rounded-5 p-md-5">
        <section>
          <div>
            <div color="black">
              <div>
                <h1>Pravila privatnosti</h1>
              </div>
            </div>
            <div>
              <p>
                Atlantic Droga Kolinska d.o.o. &nbsp;(u daljem tekstu:
                Dru&scaron;tvo) deo je poslovnog sistema Atlantic Grupe u okviru
                kog su uspostavljeni i primenjuju se visoki standardi poslovnog
                upravljanja i transparentnog poslovanja &scaron;to znači
                odgovorno, zakonito i etično pona&scaron;anje svih pravnih
                subjekata Atlantic Grupe koji ujedno obuhvataju
                po&scaron;tovanje privatnosti i za&scaron;titu podataka o
                ličnosti.
              </p>
              <p>
                Uzimajući u obzir pomenute standarde, Dru&scaron;tvo kao
                rukovalac u okviru poslovnog sistema Atlantic Grupe zakonito,
                po&scaron;teno i transparentno prikuplja i obrađuje podatke o
                ličnosti u skladu sa važećim propisima i preduzima odgovarajuće
                tehničke i organizacione mere radi za&scaron;tite podataka o
                ličnosti od nezakonitog pristupa i zloupotrebe.
              </p>
              <p>
                Dru&scaron;tvo je svesno da je za&scaron;tita podataka o
                ličnosti važna kako za na&scaron;e korisnike tako i za druge
                pojedince. Upravo zato želimo da vas obavestimo koje podatke o
                ličnosti prikupljamo, kako upravljamo njima i kako se staramo
                oko njihove za&scaron;tite.
              </p>
            </div>
            <div>
              <div color="primary">
                <div>
                  <h5>Svrha, obim i pravni osnov obrade</h5>
                </div>
              </div>
              <p>
                Podaci o ličnosti se prikupljaju u posebne, izričite i zakonite
                svrhe, u neophodnom obimu i obrađuju se na način koji je u
                skladu sa tim svrhama.
              </p>
            </div>
            <div>
              <div color="primary">
                <div>
                  <h5>
                    Dru&scaron;tvo će podatke o ličnosti prikupljati i
                    obrađivati samo kada je to dozvoljeno propisima:
                  </h5>
                </div>
              </div>
              <ul>
                <li>
                  <p>
                    kada je obrada potrebna radi ispunjavanja zakonske obaveze
                  </p>
                </li>
                <li>
                  <p>
                    kada pojedinac da pristanak za obradu svojih podatka o
                    ličnosti;
                  </p>
                </li>
                <li>
                  <p>
                    kada je obrada potrebna za izvr&scaron;avanje ugovora čiji
                    je ugovarač pojedinac na kog se odnose podaci o ličnosti ili
                    za preduzimanje mera na zahtev takvog pojedinca pre
                    zaključenja ugovora;
                  </p>
                </li>
                <li>
                  <p>
                    kada je obrada potrebna za za&scaron;titu životnih interesa
                    pojedinca na kog se odnose podaci o ličnosti ili drugog
                    fizičkog lica;
                  </p>
                </li>
                <li>
                  <p>
                    kada je obrada potrebna za zakonite interese Dru&scaron;tva
                    ili povezanih dru&scaron;tava.
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <div color="primary">
                <div>
                  <h5>Prikupljanje i kori&scaron;ćenje podatka o ličnosti</h5>
                </div>
              </div>
              <p>
                Podaci o ličnosti su informacije koje vas neposredno ili
                posredno identifikuju. To su na primer va&scaron;e ime i
                prezime, elektronska adresa, broj telefona, IP adresa ili
                po&scaron;tanska adresa.
              </p>
              <p>
                Dru&scaron;tvo ne prikuplja va&scaron;e podatke o ličnosti, osim
                kada vi izričito dostavite podatke takve vrste (na primer u
                okviru upita vezanog za određeni proizvod ili uslugu, prilikom
                zaključenja ugovora ili u okviru zahteva za određeno postupanje
                pre zaključenja ugovora, uključivanjem u programe vernosti,
                prilikom prijave za primanje elektronskih vesti, uče&scaron;ćem
                u anketama, konkursima i nagradnima igrama, poručivanjem
                oglednih primeraka ili bro&scaron;ura, u okviru molbi za
                donacije i prilikom traženja određenih informacija, predajom
                biografije ili otvorene ponude ili prilikom prijave na konkurse
                za slobodna radna mesta, kao i zapo&scaron;ljavanjem u
                na&scaron;em Dru&scaron;tvu&hellip;).
              </p>
              <p>
                Va&scaron;e podatke o ličnosti prikupljamo, čuvamo, koristimo
                ili prenosimo u obimu &ndash; sadržinskom i vremenskom &ndash;
                koji je potreban u određenom slučaju, na primer da odgovorimo na
                va&scaron;a pitanja, ispunimo va&scaron;e zahteve, da vas
                obavestimo o svojim proizvodima ili rezultatima konkursa ili
                nagradne igre, da ispunimo ugovornu obavezu ili preduzmemo
                neophodne radnje pre zaključenja ugovora ili da ispunimo druge
                zakonske i pravne obaveze. Zbog tih razloga Dru&scaron;tvo mora
                ponekad da prenese podatke o ličnosti drugim dru&scaron;tvima u
                Atlantic Grupi i povezanim dru&scaron;tvima ili spoljnim
                pružaocima usluga na dalju obradu. Pružaoci usluga mogu na
                primer da budu angažovani u kontekstu usluga pozivnog centra,
                distribucije robe ili oglasnog materijala ili za organizaciju i
                sprovođenje nagradnih igara. Dru&scaron;tvo od tih lica traži da
                prihvate važeće pravne propise, postupaju u skladu sa
                na&scaron;im uputstvima i uzmu u obzir na&scaron;a interna
                pravila o za&scaron;titi podataka o ličnosti, kao i da preduzmu
                odgovarajuće organizacione i tehničke mere za&scaron;tite. Zbog
                tih razloga va&scaron;i podaci o ličnosti moraće da se prenesu i
                u druge zemlje EU ili zemlje van EU, pri čemu će se
                Dru&scaron;tvo prilikom takvog prenosa postarati da on bude u
                potpunosti u skladu sa zakonom i da bude izvr&scaron;en uz sve
                raspoložive i odgovarajuće mere za&scaron;tite podataka o
                ličnosti.
              </p>
              <p>
                Dru&scaron;tvo garantuje da neće nikome prodati va&scaron;e
                podatke o ličnosti, da ih neće proslediti ili posuditi trećim
                licima ukoliko to ne bude potrebno za ispunjenje određene svrhe
                u koju ste nam dali podatke o ličnosti, a osim toga garantuje da
                va&scaron;i podatke o ličnosti neće dati nijednom
                neovla&scaron;ćenom primaocu.
              </p>
              <p>
                Postoji mogućnost da ćemo morati va&scaron;e podatke o ličnosti
                da dostavimo nadležnom organu javne vlasti ukoliko je to
                predviđeno zakonom ili drugim obavezujućim propisom.
              </p>
              <p>
                Prikupljeni podaci čuvaće se koliko god je to potrebno za svrhu
                u koju su prikupljeni, odnosno do roka određenog važećim
                propisima.
              </p>
            </div>
            <div>
              <div color="primary">
                <div>
                  <h5>
                    Kori&scaron;ćenje podataka o ličnosti u svrhe
                    ogla&scaron;avanja i marketinga
                  </h5>
                </div>
              </div>
              <p>
                Kada se va&scaron;i podaci o ličnosti koriste u svrhe
                ogla&scaron;avanja i marketinga u skladu sa važećim zakonskim
                propisima iz oblasti za&scaron;tite podataka o ličnosti,
                va&scaron;i podaci čuvaju se i koriste u te svrhe, na primer za
                slanje elektronskih obave&scaron;tenja, oglednih primeraka
                proizvoda, poziva za uče&scaron;će u konkursima i nagradnim
                igrama putem elektronske po&scaron;te, ili po&scaron;te, ili
                drugog komunikacionog kanala koji ste prihvatili.
              </p>
              <p>
                Ukoliko se va&scaron;i podaci o ličnosti budu koristili u svrhu
                slanja individualnih (vama) prilagođenih oglasa i promocija radi
                kreiranja i održavanja korisničkog profila, o tom ćete biti
                unapred obave&scaron;teni, dakle pre slanja takvih oglasa i
                promocija, a pre takve obrade podataka o ličnosti posebno ćemo
                vas izričito i informisano zamoliti za pristanak.
              </p>
              <p>
                Va&scaron;e podatke ćemo takođe upotrebiti ukoliko budemo želeli
                da analiziramo i unapredimo efikasnost na&scaron;ih veb-usluga,
                ogla&scaron;avanja, marketinga, trži&scaron;nih istraživanja i
                prodajnih napora u anketama ili raznim drugim upitnicima ili na
                način koji će obezbediti da va&scaron;i podaci o ličnosti ne
                budu navedeni imenom, odnosno da ne mogu da se povežu sa
                konkretnim licem.
              </p>
            </div>
            <div>
              <div color="primary">
                <div>
                  <h5>Ostvarivanje prava pojedinca</h5>
                </div>
              </div>
              <p>
                U bilo kom trenutku možete od nas da zatražite informaciju o
                svojim podacima o ličnosti koje obrađujemo ili da zatražite
                njihovo ispravljanje, da opozovete njihovo kori&scaron;ćenje ili
                da zatražite brisanje svojih podataka o ličnosti ili da
                opozovete pristanak za sve ili samo neke svrhe (osim kada to
                nije moguće, na primer kada je reč o obradi podataka u
                statističke svrhe u vezi sa kojim podaci ni u kom slučaju ne
                omogućavaju identifikaciju), odnosno zabranite prenos podataka
                drugom rukovaocu pod zakonskim uslovima, a osim toga možete da
                otkažete primanje na&scaron;ih informativnih i promotivnih
                materijala.
              </p>
              <p>
                Takođe možete da istaknete prigovor na kori&scaron;ćenje svojih
                podataka o ličnosti u situacijama zasnovanim na postojanju
                na&scaron;eg legitimnog interesa, naročito kada ih koristimo za
                potrebe direktnog marketinga.
              </p>
              <p>
                Sa na&scaron;e strane ćemo se u najvećoj mogućoj i razumnoj meri
                starati da podaci o ličnosti koje nam stavite na raspolaganje
                budu pravilno evidentirani, a ujedno je odgovornost za tačnost i
                na vama, pa vas molimo da podatke redovno i blagovremeno
                ažurirate, odnosno da nam dostavite zahtev za upis promene ili
                za ispravku.
              </p>
              <p>
                U vezi sa ostvarivanjem svojih prava i obaveza oko
                kori&scaron;ćenja podataka o ličnosti možete da se obratite na
                elektronsku adresu ili po&scaron;tansku adresu sedi&scaron;ta
                Dru&scaron;tva koje su navedene na veb-sajtu ili na elektronsku
                adresu: dpo@atlanticgrupa.com ili prema uputstvima na
                dostavljenim promotivnim materijalima.
              </p>
              <p>
                Posle prijema va&scaron;ih zahteva, sprove&scaron;ćemo zatraženu
                aktivnost u odgovarajućem roku, ali najdocnije u roku od 30 dana
                od dana prijema, i o tome vas prema potrebi obavestiti, a sve u
                skladu sa zakonom.
              </p>
            </div>
            <div>
              <div color="primary">
                <div>
                  <h5>Deca</h5>
                </div>
              </div>
              <p>
                Ukoliko bismo ipak u neke svrhe prikupljali podatke o deci koja
                imaju toliko godina da je u skladu sa važećim zakonima za obradu
                njihovih podataka o ličnosti potreban pristanak roditelja, o
                tome ćemo objaviti odgovarajuće informacije i preduzeti razumne
                mere kako bismo utvrdili da li deca imaju pristanak roditelja,
                odnosno zakonskog staraoca.
              </p>
              <p>
                Podatke o deci, po pravilu, ne prikupljamo. Ukoliko utvrdimo da
                su nam oni dostavljeni bez pristanka roditelja ili zakonskih
                zastupnika, obrisaćemo ih u najkraćem mogućem roku. Pri tome
                ćemo se pridržavati smernica primljenih od vas kao roditelja ili
                zakonskog staraoca.
              </p>
            </div>
            <div>
              <div color="primary">
                <div>
                  <h5>Bezbednost podataka</h5>
                </div>
              </div>
              <p>
                Implementirali smo tehničke i organizacione mere kako bismo
                sprečili gubitak va&scaron;ih podataka, njihovo menjanje,
                otuđenje, pristup njima od strane neovla&scaron;ćenog trećeg
                lica, odnosno njihovu bilo kakvu neovla&scaron;ćenu obradu.
                Pristup va&scaron;im podacima o ličnosti je ograničen samo na
                one zaposlene kojim su te informacije potrebne za pružanje
                na&scaron;ih usluga, odnosno re&scaron;avanje va&scaron;eg
                pitanja. Stalno obrazujemo svoje zaposlene o tome koliko su
                važni poverljivost podataka i čuvanje privatnosti, kao i
                za&scaron;tita va&scaron;ih podataka. Prikupljamo samo one
                podatke koji su potrebni za određenu obradu, a čuvamo ih samo
                onoliko koliko je potrebno, odnosno predviđeno zakonskim
                propisima.
              </p>
              <p>
                Na na&scaron;em veb-sajtu moći ćete povremeno da pronađete veze
                do drugih veb-sajtova ili portala, a osim toga možemo da vam
                ponudimo i karakteristike dru&scaron;tvenih mreža koje
                omogućavaju deljenje podataka o ličnosti na dru&scaron;tvenim
                mrežama. Preporučujemo vam da u svakom slučaju prethodno
                pročitate pravila o za&scaron;titi podataka o ličnosti na tim
                portalima ili dru&scaron;tvenim mrežama ili kod trećih lica, kao
                i o preduzetim merama o njihovoj za&scaron;titi jer mogu da se
                razlikuju od ovih Pravila i na&scaron;ih preduzetih mera, a za
                to mi ne možemo da budemo odgovorni.
              </p>
            </div>
            <div>
              <div color="primary">
                <div>
                  <h5>Ograničenje odgovornosti</h5>
                </div>
              </div>
              <p>
                Mada preduzimamo dostupne tehničke, organizacione i kadrovske
                mere za za&scaron;titu podataka o ličnosti od slučajne ili
                namerne zloupotrebe, uni&scaron;tenja, gubitka,
                neovla&scaron;ćenih promena ili pristupa, ne možemo da
                garantujemo da uprkos odredbama ovih Pravila o za&scaron;titi
                privatnosti neki podaci o ličnosti koje prikupimo neće nikad
                biti slučajno otkriveni.
              </p>
              <p>
                U najvećoj meri dozvoljenoj zakonom isključujemo odgovornost za
                &scaron;tetu nanetu korisnicima ili trećim licima slučajnim
                otkrivanjem podataka o ličnosti.
              </p>
              <p>
                Budući da nemamo nadzor nad podacima o ličnosti koje dostavite
                prilikom pristupa drugim portalima i nad kori&scaron;ćenjem
                takvih podataka ili neposredno nad trećim licima (na primer
                prilikom uče&scaron;ća u sponzorisanim aktivnostima), u najvećoj
                meri dozvoljenoj zakonom isključujemo odgovornost za
                &scaron;tetu koju bi vi ili treće lice moglo da pretrpi usled
                prosleđivanja podataka o ličnosti.
              </p>
            </div>
            <div>
              <div color="primary">
                <div>
                  <h5>Ažuriranje i izmene</h5>
                </div>
              </div>
              <p>
                Zadržavamo pravo na izmene ili ažuriranje delova ovih Pravila u
                svakom trenutku i bez prethodne najave. Posetite povremeno
                na&scaron; veb-sajt kako biste bili upoznati sa izmenama ili
                ažuriranjima Pravila. Biće objavljen i datum početka važenja
                aktuelne verzije Pravila.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default LegalSL;
