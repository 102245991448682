import { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import {
  Routes,
  Route,
  useLocation,
  Link,
  useNavigate,
} from "react-router-dom";

import "./i18n/config";
import CardGenerator from "./CardGenerator";
import Login from "Login";
import Admin from "./Admin";
import Result from "./Result";
import ScrollToTop from "./utils/scrollToTop";
import Footer from "views/Home/Footer";
import Legal from "Legal";
import Cookies from "Cookies";
import CookieNotice from "views/CookieNotice";
import { getLocalStorage } from "utils/getLocalStorage";
import { useTranslation } from "react-i18next";

function App() {
  const [showCookieNotice, setShowCookieNotice] = useState(false);
  const [baseDomain, setBaseDomain] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  var hostname = window.location.hostname;
  var port = window.location.port;

  const checkPw = () => {
    // gets the current input value
    const answer = document.getElementById("password").value;

    if (answer === "argeta6712!") {
      setIsVerified(true);
    } else {
      alert("Nope, wrong password.");
    }
  };

  useEffect(() => {
    if (!getLocalStorage("hasAccepted")) {
      setShowCookieNotice(true);
    }

    switch (hostname) {
      case "zgodbarcek.junior-adventures.com":
        i18n.changeLanguage("sl");
        setIsVerified(true);
        setBaseDomain(process.env.REACT_APP_DOMAIN_SL);
        break;
      case "pripovijedac.junior-adventures.com":
        i18n.changeLanguage("hr");
        setIsVerified(true);
        setBaseDomain(process.env.REACT_APP_DOMAIN_HR);
        break;
      case "pripovedac.junior-adventures.com":
        i18n.changeLanguage("rs");
        setIsVerified(true);
        setBaseDomain(process.env.REACT_APP_DOMAIN_RS);
        break;
      default:
        i18n.changeLanguage("rs");
        setIsVerified(true);
        setBaseDomain(process.env.REACT_APP_DOMAIN_RS);
        break;
    }
  }, []);

  const LoadAdminView = () => {
    useEffect(() => {
      localStorage.setItem("isAdmin", true);
      navigate("/");
    }, []);

    return <div>{/* Your admin page content here */}</div>;
  };

  if (baseDomain === null) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ScrollToTop />

      {isVerified ? (
        <>
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={
                <CardGenerator
                  showCookieNotice={showCookieNotice}
                  setShowCookieNotice={setShowCookieNotice}
                  baseDomain={baseDomain}
                />
              }
            />
            <Route
              path="/result/:userId"
              element={<Result type="sender" baseDomain={baseDomain} />}
            />
            <Route path="/login" element={<Login baseDomain={baseDomain} />} />
            <Route path="/admin" element={<Admin baseDomain={baseDomain} />} />
            <Route
              path="/pravila-zasebnosti"
              element={<Legal currentLanguage={currentLanguage} />}
            />
            <Route
              path="/piskotki"
              element={<Cookies currentLanguage={currentLanguage} />}
            />
            <Route path="/adminnja" element={<LoadAdminView />} />
          </Routes>
          <Footer
            showCookieNotice={showCookieNotice}
            setShowCookieNotice={setShowCookieNotice}
          />
          {showCookieNotice && (
            <CookieNotice
              showCookieNotice={showCookieNotice}
              setShowCookieNotice={setShowCookieNotice}
            />
          )}
        </>
      ) : (
        <div className="vh-100 d-flex align-items-center justify-content-center">
          <form onSubmit={checkPw}>
            <div className="m-2">
              <label htmlFor="password">Password</label>
              <input type="password" name="password" id="password" />
            </div>
            <div className="m-2">
              <button>Login</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default App;
