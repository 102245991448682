import { useTranslation } from "react-i18next";

const Hero = ({ images, count, trackEvent }) => {
  const { t } = useTranslation();

  return (
    <div className="hero hero__primary position-relative pt-3 pt-md-7 pb-6 pb-md-9 ">
      <div className="container position-relative">
        <div className="counter d-flex align-items-center justify-content-center text-center">
          <div>
            <span>{t("created1")}</span>
            <span className="strong">{count}</span>
            <span>{t("created2")}</span>
          </div>
        </div>
        <div className="content pt-6 pt-sm-6 pe-md-6">
          <h1
            className="mt-5"
            dangerouslySetInnerHTML={{
              __html: t("HeroH1"),
            }}
          />
          <p
            className="lead"
            dangerouslySetInnerHTML={{
              __html: t("HeroH2"),
            }}
          />
        </div>

        <a href="#ustvari">
          <button
            onClick={() =>
              trackEvent("user_interaction", "click", "jumpto_create", "head")
            }
            className="btn btn-yellow mt-3 py-3 px-4 text-uppercase text-white">
            {t("HeroCTA")}
          </button>
        </a>
      </div>
    </div>
  );
};

export default Hero;
