import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

const Form = ({
  images,
  promptVar,
  updatePromptVar,
  handleChangeAge,
  generateText,
  loading,
  disabled,
  disabledMsg,
  recaptchaRef,
}) => {
  const { t } = useTranslation();

  return (
    <div className="position-relative" id="form">
      <div className="container narrower bg-white rounded-5 text-center pt-5 px-lg-7 ">
        {disabled && (
          <p className="mt-2 fw-bold text-pink-dark mb-5">{t("FormMand")}</p>
        )}
        {disabledMsg !== "" && (
          <p className="mt-2 fw-bold text-orange mb-5">{disabledMsg}</p>
        )}
        <div className="form ">
          <div
            className={`group mb-5 ${
              promptVar.gender === "" && disabled && "error"
            }`}>
            <div className="mb-3">
              <h3 className="">{t("Form1H")}</h3>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                id="female"
                value={t("Form1F")}
                checked={promptVar.gender === t("Form1F")}
                onChange={(e) =>
                  updatePromptVar({
                    gender: e.target.value,
                  })
                }
              />
              <label className="form-check-label" htmlFor="female">
                {t("Form1F")}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                id="male"
                value={t("Form1M")}
                checked={promptVar.gender === t("Form1M")}
                onChange={(e) =>
                  updatePromptVar({
                    gender: e.target.value,
                  })
                }
              />
              <label className="form-check-label" htmlFor="male">
                {t("Form1M")}
              </label>
            </div>
          </div>

          <div className={`group px-lg-5 `}>
            <div className="mb-3">
              <h3 className="">{t("Form2H")}</h3>
              <p dangerouslySetInnerHTML={{ __html: t("Form2P") }} />
            </div>

            <div className="row g-2">
              <div
                className={`col-8 ${
                  promptVar.firstname === "" && disabled && "error"
                }`}>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  placeholder={t("Form2Name")}
                  onChange={(e) =>
                    updatePromptVar({ firstname: e.target.value })
                  }
                />
              </div>
              <div
                className={`col-4 ${
                  promptVar.age === "" && disabled && "error"
                }`}>
                <input
                  type="number"
                  inputMode="decimal"
                  onWheel={(e) => e.target.blur()}
                  className="form-control"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  id="age"
                  placeholder={t("Form2Age")}
                  value={promptVar.age}
                  onChange={handleChangeAge}
                />
              </div>
            </div>
          </div>

          <div className="my-5 group px-lg-6 ">
            <div className="mb-3">
              <h3 className="">{t("Form3H")}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    promptVar.gender === t("Form1F")
                      ? t("Form3PF")
                      : t("Form3PM"),
                }}
              />
            </div>

            <div className="row g-2">
              <div className="col-12 col-sm">
                <input
                  type="text"
                  className="form-control"
                  id="hero"
                  placeholder={t("Form3Item")}
                  onChange={(e) =>
                    updatePromptVar({ heroitem: e.target.value })
                  }
                />
                <small className="text-orange text-start d-block pt-2 w-100 text-center">
                  {t("optionalField")}
                </small>
              </div>
            </div>
          </div>

          <div
            className={`group my-5 ${
              promptVar.genre === "" && disabled && "error"
            }`}>
            <div className="mb-3">
              <h3 className="">{t("Form4H")}</h3>
              <p dangerouslySetInnerHTML={{ __html: t("Form4P") }} />
            </div>

            <div className="row g-0 g-sm-4 d-flex justify-content-center radio-image">
              <div className="col-6 col-md-3">
                <input
                  type="radio"
                  name="test"
                  id="adventure"
                  value={t("Form4O1")}
                  checked={promptVar.genre === t("Form4O1")}
                  onChange={(e) =>
                    updatePromptVar({
                      genre: e.target.value,
                    })
                  }
                />
                <label htmlFor="adventure">
                  <img src={images["icon-pustolovska.svg"]} />
                  <p>{t("Form4O1")}</p>
                </label>
              </div>

              <div className="col-6 col-md-3">
                <input
                  type="radio"
                  name="test"
                  id="funny"
                  value={t("Form4O2")}
                  checked={promptVar.genre === t("Form4O2")}
                  onChange={(e) =>
                    updatePromptVar({
                      genre: e.target.value,
                    })
                  }
                />
                <label htmlFor="funny">
                  <img src={images["icon-smesna.svg"]} />
                  <p>{t("Form4O2ALT")}</p>
                </label>
              </div>

              <div className="col-6 col-md-3">
                <input
                  type="radio"
                  name="test"
                  id="fairy"
                  value={t("Form4O3")}
                  checked={promptVar.genre === t("Form4O3")}
                  onChange={(e) =>
                    updatePromptVar({
                      genre: e.target.value,
                    })
                  }
                />
                <label htmlFor="fairy">
                  <img src={images["icon-pravljicna.svg"]} />
                  <p>{t("Form4O3")}</p>
                </label>
              </div>

              <div className="col-6 col-md-3">
                <input
                  type="radio"
                  name="test"
                  id="detective"
                  value={t("Form4O4")}
                  checked={promptVar.genre === t("Form4O4")}
                  onChange={(e) =>
                    updatePromptVar({
                      genre: e.target.value,
                    })
                  }
                />
                <label htmlFor="detective">
                  <img src={images["icon-detektivska.svg"]} />
                  <p>{t("Form4O4")}</p>
                </label>
              </div>
            </div>
          </div>

          <div className="my-5 group">
            <div className="mb-3">
              <h3 className="">{t("Form5H")}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("Form5P"),
                }}
              />
            </div>

            <div className="radio-text">
              <div className="d-inline-block mx-2">
                <input
                  type="radio"
                  name="lesson"
                  id="environment"
                  value={t("Form5O1")}
                  checked={promptVar.lesson === t("Form5O1")}
                  onChange={(e) => updatePromptVar({ lesson: e.target.value })}
                />
                <label htmlFor="environment">
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: t("Form5O1"),
                    }}
                  />
                </label>
              </div>

              <div className="d-inline-block mx-2 d-none">
                <input
                  type="radio"
                  name="lesson"
                  id="equality"
                  value={t("Form5O2")}
                  checked={promptVar.lesson === t("Form5O2")}
                  onChange={(e) => updatePromptVar({ lesson: e.target.value })}
                />
                <label htmlFor="equality">
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: t("Form5O2"),
                    }}
                  />
                </label>
              </div>

              <div className="d-inline-block mx-2">
                <input
                  type="radio"
                  name="lesson"
                  id="friendship"
                  value={t("Form5O3")}
                  checked={promptVar.lesson === t("Form5O3")}
                  onChange={(e) => updatePromptVar({ lesson: e.target.value })}
                />
                <label htmlFor="friendship">
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: t(t("Form5O3")),
                    }}
                  />
                </label>
              </div>

              <div className="d-inline-block mx-2">
                <input
                  type="radio"
                  name="lesson"
                  id="helpfellow"
                  value={t("Form5O4")}
                  checked={promptVar.lesson === t("Form5O4")}
                  onChange={(e) => updatePromptVar({ lesson: e.target.value })}
                />
                <label htmlFor="helpfellow">
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: t(t("Form5O4")),
                    }}
                  />
                </label>
              </div>

              <small className="text-orange text-start d-block pt-2 w-100 text-center">
                {t("optionalField")}
              </small>
            </div>
          </div>

          <div className="row d-flex justify-content-center my-5">
            <p dangerouslySetInnerHTML={{ __html: t("FormBottom") }} />
            <div>
              <button
                onClick={() => generateText()}
                className={`btn btn-orange px-4 py-3 text-white d-flex align-items-center justify-content-center  mx-auto`}
                type="button"
                disabled={loading && "disabled"}>
                {loading && (
                  <span
                    className="spinner-border spinner-border-md me-3"
                    role="status"
                    aria-hidden="true"></span>
                )}

                {loading ? t("FormGenerating") : t("FormGenerate")}
              </button>
            </div>
          </div>
          <div className="text-center d-inline-block mt-3">
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              badge="inline"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
