function LegalHR() {
  return (
    <div className="hero hero__secondary position-relative bg-white px-2 px-sm-0">
      <div className="container narrow bg-white rounded-5 p-md-5">
        <h1>Pravne obavijesti</h1>

        <h2>Autorska prava</h2>
        <p>
          Sav sadržaj objavljen na Argetinim internetskim stranicama vlasništvo
          je trgovačkog društva ATLANTIC DROGA KOLINSKA d.o.o. te je u zakonski
          dopuštenim okvirima predmet autorske zaštite ili drugih oblika zaštite
          intelektualnog vlasništva.
        </p>
        <p>
          Sadržaji (tekstovi, slike, logotipi…) objavljeni na ovim internet
          stranicama mogu se rabiti isključivo u nekomercijalne svrhe, a ne
          smiju se mijenjati, prepisivati, umnožavati, ponovo objavljivati,
          slati poštom ili na bilo koji drugi način distribuirati u komercijalne
          svrhe bez izričitoga pismenog odobrenja društva ATLANTIC DROGA
          KOLINSKA d.o.o.
        </p>
        <p>
          Sve reprodukcije ili primjerci sadržaja ovih internet stranica moraju
          zadržati sve navedene oznake autorskih prava te ostale obavijesti o
          pravima intelektualnog vlasništva ili obavijesti o drugim pravima
          (znak © 2015 ATLANTIC DROGA KOLINSKA d.o.o. Sva prava pridržana.
          ATLANTIC DROGA KOLINSKA d.o.o. dio je Atlantic Grupe).
        </p>
        <p>
          Robne marke i uslužne marke koje se pojavljuju na ovim stranicama
          registrirane su robne marke čiji je vlasnik ili korisnik licence
          društvo ATLANTIC DROGA KOLINSKA d.o.o. (u daljnjem tekstu Društvo) ili
          njegova povezana trgovačka društva. Upotreba tih robnih marki izričito
          je zabranjena, osim u slučajevima definiranim ovim tekstom.
        </p>
        <h2>Pravila privatnosti</h2>
        <p>
          ATLANTIC DROGA KOLINSKA d.o.o. (u daljnjem tekstu: Društvo) dio je
          poslovnog sustava Atlantic Grupe u okviru kojeg su uspostavljeni i
          primjenjuju se visoki standardi poslovnog upravljanja i transparentnog
          poslovanja, što znači odgovorno, zakonito i etično utemeljeno
          ponašanje svih pravnih subjekata Atlantic Grupe koji istodobno
          uključuju i poštovanje privatnosti i zaštitu osobnih podataka.
        </p>
        <p>
          Uz poštovanje spomenutih standarda, Društvo kao voditelj obrade, u
          okviru poslovnog sustava Atlantic Grupe, prikuplja i obrađuje osobne
          podatke zakonito, pošteno i transparentno, u skladu s važećim
          propisima te provodi odgovarajuće tehničke i organizacijske mjere zbog
          zaštite osobnih podataka od nezakonitog pristupa i zlouporabe.
        </p>
        <p>
          Društvo je svjesno da je zaštita osobnih podataka važna našim
          korisnicima i drugim osobama. Upravo zbog toga želimo vas obavijestiti
          koje osobne podatke prikupljamo, kako njima upravljamo i kako se
          brinemo o njihovoj zaštiti.
        </p>
        <h2>Svrha, opseg i pravni temelj obrade</h2>
        <p>
          Osobni podatci prikupljaju se za posebne, izričite i zakonite svrhe u
          nužnom opsegu i obrađuju se na način koji je usklađen s tim svrhama.
        </p>
        <p>
          Društvo će prikupljati i obrađivati osobne podatke samo u slučajevima
          u kojima to dozvoljavaju propisi:
        </p>
        <ul>
          <li>kada je obrada potrebna zbog ispunjenja zakonske obveze;</li>
          <li>kada osoba da privolu za obradu svojih osobnih podataka;</li>
          <li>
            kada je obrada potrebna za izvršenje ugovora u kojem je osoba
            ugovorna strana na koju se odnose osobni podatci ili zbog provođenja
            mjera na zahtjev takve osobe prije sklapanja ugovora;
          </li>
          <li>
            kada je obrada potrebna radi zaštite životnih interesa osobe na koju
            se odnose osobni podatci ili druge fizičke osobe;
          </li>
          <li>
            kada je obrada potrebna za zakonite interese Društva ili povezanih
            društava.
          </li>
        </ul>
        <h2>Prikupljanje i upotreba osobnih podataka</h2>
        <p>
          Osobni podatci su informacije kojima vas je moguće neposredno ili
          posredno identificirati. To su, primjerice, vaše ime i prezime,
          elektronička adresa, telefonski broj, IP adresa ili poštanska adresa.
        </p>
        <p>
          Društvo ne prikuplja vaše osobne podatke, osim u slučaju kada vi
          izričito dostavite takve podatke (primjerice prilikom slanja upita u
          vezi s određenim proizvodom ili uslugom, sklapanja ugovora ili slanja
          zahtjeva u vezi s određenim postupanjem prije sklapanja ugovora,
          uključivanja u programe vjernosti, prilikom prijave za primanje
          e-novosti, sudjelovanja u anketama, natječajima i nagradnim igrama,
          naručivanja uzoraka ili brošura, slanja molbi za donacije i traženja
          određenih informacija, slanja životopisa ili otvorene ponude ili
          prijave na natječaje za slobodna radna mjesta te zapošljavanja u našem
          Društvu…).
        </p>
        <p>
          Vaše osobne podatke prikupljamo, čuvamo, upotrebljavamo ili prenosimo
          u opsegu – sadržajnom i vremenskom – koji je potreban u određenom
          slučaju, primjerice kako bismo odgovorili na vaša pitanja, ispunili
          vaše zahtjeve, obavijestili vas o svojim proizvodima ili rezultatima
          natječaja ili nagradne igre, ispunili ugovornu obvezu ili hitne
          postupke prije sklapanja ugovora ili ispunili svoje druge zakonske i
          pravne obveze. Društvo stoga mora osobne podatke ponekad prenijeti
          drugim društvima u Atlantic Grupi i povezanim društvima ili vanjskim
          pružateljima usluga na daljnju obradu. Pružatelji usluga mogu biti
          angažirani primjerice u kontekstu usluga pozivnog centra, distribucije
          robe ili reklamnog materijala ili za organizaciju i izvedbu nagradnih
          igara. Društvo od tih strana zahtijeva prihvaćanje valjanih pravnih
          propisa te postupanje u skladu s našim uputama i poštivanje naših
          internih pravila o zaštiti osobnih podataka, kao i primjenu
          odgovarajućih organizacijskih i tehničkih mjera zaštite. Stoga će vaši
          osobni podatci morati biti preneseni i u druge države EU-a ili države
          izvan EU-a, ali će Društvo pri takvu prijenosu osigurati potpunu
          usklađenost sa zakonom te se pobrinuti da bude proveden uz sve
          dostupne i odgovarajuće mjere zaštite osobnih podataka.
        </p>
        <p>
          Društvo jamči da vaše osobne podatke neće prodavati, prosljeđivati ili
          posuđivati trećim stranama ako to ne bude potrebno za ispunjenje
          određene svrhe za koju ste nam dostavili osobne podatke te također
          jamči da vaše osobne podatke neće davati nijednom neovlaštenom
          primatelju.
        </p>
        <p>
          Postoji mogućnost da ćemo vaše osobne podatke morati dostaviti
          nadležnom tijelu javne vlasti kada to zahtijeva zakon ili neki drugi
          obvezujući propis.
        </p>
        <p>
          Prikupljeni podatci čuvat će se dok to bude potrebno za svrhu za koju
          su prikupljeni, odnosno do roka utvrđenog u valjanim propisima.
        </p>
        <h2>Upotreba osobnih podataka u svrhe oglašavanja i prodaje</h2>
        <p>
          Kad se vaši osobni podatci upotrebljavaju za svrhe reklamiranja i
          prodaje u skladu s valjanim zakonskim propisima s područja zaštite
          osobnih podataka, vaši će podatci biti spremljeni i upotrijebljeni za
          te svrhe, primjerice za slanje e-obavijesti, uzoraka proizvoda, poziva
          na sudjelovanje u natječajima i nagradnim igrama e-poštom ili nekim
          drugim komunikacijskim kanalom koji ste prihvatili.
        </p>
        <p>
          Ako će se vaši osobni podatci upotrebljavati za slanje individualnih
          (vama) prilagođenih reklama i promotivnih akcija radi stvaranja i
          održavanja korisničkog profila, o tome ćete biti obaviješteni
          unaprijed, dakle prije slanja takvih reklama i promotivnih akcija, a
          prije takve obrade osobnih podataka svakako ćemo vas za privolu
          zamoliti odvojeno, izričito i informirano.
        </p>
        <p>
          Vaše ćemo podatke upotrijebiti i za provedbu analize te poboljšanje
          učinkovitosti naših internet usluga, reklamiranja, prodaje,
          istraživanja tržišta i prodajnih aktivnosti u anketama ili raznim
          drugim upitnicima ili na način koji će osiguravati da se vaši osobni
          podatci ne navedu s imenom, odnosno da se ne mogu povezati s
          konkretnom osobom.
        </p>
        <h2>Ostvarenje prava privatnih osoba</h2>
        <p>
          U svakom trenutku od nas možete zahtijevati informaciju o svojim
          osobnim podatcima koje obrađujemo ili zahtijevati njihov ispravak,
          opozvati njihovu upotrebu ili zatražiti brisanje svojih osobnih
          podataka ili opozvati privolu za sve ili samo za neke svrhe (osim kada
          to nije moguće, primjerice kad je riječ o obradi podataka u
          statističke svrhe kod koje osobni podatci nikako ne omogućuju
          identifikaciju), odnosno zabraniti prijenos podataka drugom voditelju
          obrade pod zakonskim uvjetima, a možete i otkazati primanje naših
          informativnih i promidžbenih materijala.
        </p>
        <p>
          Također možete uložiti prigovor na upotrebu svojih osobnih podataka u
          situacijama koje se zasnivaju na postojanju našeg legitimnog interesa,
          posebice kad ih upotrebljavamo za potrebe izravne prodaje.
        </p>
        <p>
          Mi ćemo se u najvećoj mogućoj i razumnoj mjeri brinuti o tome da
          osobni podatci koje ste nam stavili na raspolaganje budu pravilno
          evidentirani, ali odgovornost za točnost snosite i vi, stoga vas
          molimo da podatke redovito i pravodobno ažurirate, odnosno da nam
          dostavite zahtjev za unos izmjene ili ispravka.
        </p>
        <p>
          Za ostvarenje svih svojih prava i obveza u vezi s uporabom osobnih
          podataka možete se obratiti na e-adresu ili adresu sjedišta Društva
          koje se nalaze na internetskoj stranici ili na e-adresu:
          dpo@atlanticgrupa.com ili prema uputi na dostavljenim promidžbenim
          materijalima.
        </p>
        <p>
          Prilikom uspostave kontakta i primitka navedenih zahtjeva uložit ćemo
          razumne napore da potvrdimo vaš identitet i spriječimo neautoriziranu
          obradu osobnih podataka.
        </p>
        <p>
          Nakon primitka vaših zahtjeva, traženu aktivnost provest ćemo u
          odgovarajućem roku, ali najkasnije u roku od 30 dana od primitka te
          vas o tome prema potrebi obavijestiti, sve u skladu sa zakonom.
        </p>
        <h2>Djeca</h2>
        <p>
          U pravilu ne prikupljamo podatke o djeci. Ako utvrdimo da su nam oni
          dostavljeni bez privole roditelja ili zakonskih zastupnika, izbrisat
          ćemo ih u najkraćem mogućem roku. Pritom ćemo poštovati smjernice koje
          smo primili od vas kao roditelja ili zakonskog zastupnika.
        </p>
        <p>
          Ako za neke svrhe ipak prikupimo podatke o djeci u dobi u kojoj je u
          skladu s važećim zakonima za obradu osobnih podataka potrebna privola
          roditelja, o tome ćemo objaviti odgovarajuću informaciju i primijeniti
          razumne mjere kako bismo utvrdili imaju li djeca privolu roditelja,
          odnosno zakonskih zastupnika.
        </p>
        <h2>Zaštita podataka</h2>
        <p>
          Implementirali smo tehničke i organizacijske mjere kako bi spriječili
          gubitak vaših podataka, mijenjanje, otuđenje ili pristup neovlaštene
          treće strane, odnosno bilo kakvu njihovu neovlaštenu obradu. Pristup
          vašim osobnim podatcima ograničen je samo na one zaposlenike kojima su
          te informacije potrebne za pružanje naših usluga, odnosno rješavanje
          vaših pitanja. Svoje zaposlenike neprestano obrazujemo u vezi s
          važnošću povjerljivosti podataka i zaštitom privatnosti te zaštitom
          vaših osobnih podataka. Prikupljamo samo one podatke koji su potrebni
          za određenu obradu i ne čuvamo ih dulje nego što je to potrebno,
          odnosno uređeno zakonskim propisima.
        </p>
        <p>
          Na našoj internetskoj stranici povremeno ćete moći pronaći poveznice
          na druge internet stranice ili portale, a možemo vam ponuditi i
          funkcije društvenih mreža koje omogućuju dijeljenje osobnih podataka.
          Preporučujemo da svakako prethodno pročitate pravila o zaštiti osobnih
          podataka na tim portalima ili društvenim mrežama ili trećim osobama,
          kao i o mjerama koje se primjenjuju radi njihove zaštite jer se mogu
          razlikovati od ovih Pravila i mjera koje mi primjenjujemo, za što mi
          ne možemo biti odgovorni.
        </p>
        <h2>Ograničenje odgovornosti</h2>
        <p>
          Iako primjenjujemo dostupne tehničke, organizacijske i kadrovske mjere
          zaštite osobnih podataka od slučajne ili namjerne zlouporabe,
          uništenja, gubitka, neovlaštenih izmjena ili pristupa, ne možemo
          jamčiti da neki osobni podatci koje prikupimo unatoč odredbama ovih
          Pravila o zaštiti privatnosti nikad neće biti slučajno otkriveni.
        </p>
        <p>
          U najvećoj mjeri dopuštenoj zakonom isključujemo odgovornost za štetu
          prouzročenu korisnicima ili trećim osobama slučajnim otkrivanjem
          osobnih podataka.
        </p>
        <p>
          Budući da nemamo nadzor nad osobnim podatcima koje dostavljate
          prilikom pristupa drugim portalima i njihovoj uporabi ili izravno
          trećim osobama (primjerice prilikom sudjelovanja u sponzoriranim
          aktivnostima), u najvećoj mjeri dopuštenoj zakonom isključujemo
          odgovornost za štetu koja bi mogla nastati vama ili trećim osobama
          zbog dostavljanja osobnih podataka.
        </p>
        <h2>Ažuriranje i izmjene</h2>
        <p>
          Pridržavamo pravo na mijenjanje ili ažuriranje dijelova ovih Pravila u
          svakom trenutku i bez prethodne najave. Povremeno posjetite našu
          internetsku stranicu kako biste se upoznali s izmjenama ili
          ažuriranjem Pravila. Bit će objavljen datum početka valjanosti
          aktualne verzije Pravila.
        </p>

        <div class="rules-changed-at">
          Posljednja izmjena ovih Pravila provedena je 4.2.2020.
        </div>
      </div>
    </div>
  );
}

export default LegalHR;
