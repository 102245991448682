import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import imgMenu from "assets/images/_lunaai/hamburger-menu.svg";
import imgMenuClose from "assets/images/_lunaai/hamburger-menu-close.svg";

const Header = ({ images, trackEvent }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();

  const menuClickHandler = (anchor) => {
    setIsMenuOpened((prev) => !prev);
  };

  const closeAndGo = (link) => {
    setIsMenuOpened((prev) => !prev);
    window.location.href = link;
  };

  useEffect(() => {
    if (isMenuOpened) {
      document.documentElement.classList.add("noscroll");
    } else {
      document.documentElement.classList.remove("noscroll");
    }
  }, [isMenuOpened]);

  return (
    <div className="header">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-sm d-block text-start position-relative">
            <Link to="/">
              {currentLanguage === "sl" && (
                <img className="logo" src={images["logo-junior.svg"]} />
              )}

              {currentLanguage === "hr" && (
                <img className="logo" src={images["logo-junior-hr.svg"]} />
              )}

              {currentLanguage === "rs" && (
                <img className="logo" src={images["logo-junior-rs.svg"]} />
              )}
            </Link>
            <div
              className="hamburger-icon d-sm-none"
              onClick={menuClickHandler}>
              <motion.img
                key={isMenuOpened}
                initial={{ rotate: 90 }}
                animate={{ rotate: 0 }}
                exit={{ rotate: -90 }}
                transition={{
                  duration: 0.5,
                }}
                src={isMenuOpened ? imgMenuClose : imgMenu}
              />
            </div>
          </div>
          <div className="col text-end d-none d-sm-block">
            <ul className="list-inline nav-desktop mb-0">
              <li
                onClick={() => (window.location.href = "/#kakodeluje")}
                className="list-inline-item lead fw-bold me-5 link-white"
                style={{ cursor: "pointer" }}>
                {t("HeaderHowL")}
              </li>

              <a
                href={t("FooterLogo1Link")}
                onClick={() =>
                  trackEvent(
                    "user_interaction",
                    "click",
                    "junior_adventures",
                    "head"
                  )
                }
                target="_blank"
                className="link-white">
                <li className="list-inline-item lead fw-bold">
                  <img
                    className="me-2 icon"
                    src={images["icon-junior-head.png"]}
                  />
                  {t("HeaderLink1")}
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>

      {isMenuOpened && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className={`backdrop mobile-nav`}>
          <div className="container text-start ">
            <ul className="list-inline nav-desktop mt-7 text-start">
              <li
                onClick={() => closeAndGo("/#kakodeluje")}
                className="list-inline-item lead link-white">
                {t("HeaderHowL")}
              </li>

              <a
                href="https://junior-adventures.com/sl"
                onClick={() =>
                  trackEvent(
                    "user_interaction",
                    "click",
                    "junior_adventures",
                    "head"
                  )
                }
                target="_blank"
                className="link-white">
                <li className="list-inline-item lead ">{t("HeaderLink1")}</li>
              </a>
            </ul>

            <button
              className="btn btn-yellow mt-3 py-3 px-4 text-uppercase text-white"
              onClick={() => closeAndGo("/#ustvari")}>
              {t("HeroCTA")}
            </button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Header;
