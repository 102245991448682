function CookiesHR() {
  return (
    <div className="hero hero__secondary position-relative bg-white px-2 px-sm-0">
      <div className="container narrow bg-white rounded-5 p-md-5">
        <div class="cook">
          <h1>Kolačići</h1>

          <p>
            Minimalnu količinu informacija (kolačića) potrebno je pohraniti na
            vašem računalu kako bi internetska stranica pravilno radila, lako se
            nadograđivala i poboljšavala vaše iskustvo pregledavanja. Više od
            90% internetskih stranica upotrebljava kolačiće, ali su prema
            propisima Europske unije obvezne zatražiti privolu korisnika.
            Korištenjem ove internetske stranice suglasni ste s uporabom
            kolačića. Međutim, ako ne prihvatite uporabu kolačića, možete
            nastaviti s pregledavanjem ove stranice, ali određene se značajke
            možda neće moći pravilno prikazati ili izvesti.
          </p>

          <h2>Pravila uporabe kolačića</h2>
          <p>
            Kako bi internet stranica pravilno funkcionirala te nadogađivala ili
            poboljšavalo vaše iskustvo prilikom pregledavanja, na vaše računalo
            mora se spremiti minimalna količina informacija (kolačići). Više od
            90 % internetskih stranica upotrebljava kolačiće, ali prema
            pravilima Europske unije za to moraju dobiti privolu korisnika.
            Korištenjem ove internet stranice suglasni ste s upotrebom kolačića,
            a ako ih ne prihvatite, možete i dalje pregledavati stranice, ali se
            pritom određene funkcije na internet stranici možda neće pravilno
            prikazati ili izvršiti.
          </p>
          <h2>Što je kolačić?</h2>
          <p>
            Kolačić je informacija koju internet stranica (koju posjećujete)
            sprema na vaše računalo. Kolačići obično spremaju vaše postavke za
            internet stranicu, kao što su, primjerice, željeni jezik ili adresa.
            Kasnije, kad ponovo otvorite tu internet stranicu, pretraživač vraća
            kolačiće koji pripadaju toj stranici. To joj omogućuje da prikaže
            informacije prilagođene vašim potrebama.
          </p>
          <p>
            Kolačići mogu spremati široku paletu informacija, uključujući osobne
            informacije (vaše ime ili e-poštu). Unatoč tome, ta se informacija
            može spremiti samo u slučaju vaše privole ućite – internetske
            stranice ne mogu dobiti pristup informacijama ako ga vi niste
            odobrili, a ne mogu pristupiti ni drugim datotekama na vašem
            računalu ili mobilnom uređaju. Zadane postavke spremanja i slanja
            kolačića vama nisu vidljive, ali možete izmijeniti svoje postavke
            pretraživača tako da sami odaberete želite li zahtjeve za spremanje
            kolačića odobriti ili odbiti, automatski izbrisati spremljene
            kolačiće prilikom zatvaranja pretraživača i slično.
          </p>
          <h2>Kako se odbijaju kolačići?</h2>
          <p>
            Odbijanjem kolačića određujete želite li dopustiti spremanje
            kolačića na svojem računalu ili mobilnom uređaju. Postavke kolačića
            mogu se nadzirati i konfigurirati u vašem pretraživaču. Ako kolačiće
            ne prihvatite, može se dogoditi da nećete moći upotrebljavati
            određene funkcije na internet stranici.
          </p>
          <h2>Što su privremeni kolačići?</h2>
          <p>
            Privremeni kolačići ili kolačići sesije brišu se iz računala nakon
            što zatvorite pretraživač. Pomoću njih internetska mjesta spremaju
            privremene podatke, primjerice proizvode u košarici za kupnju.
          </p>
          <h2>Što su trajni kolačići?</h2>
          <p>
            Trajni ili spremljeni kolačići ostaju na računalu i nakon što ste
            zatvorili program pretraživača. Pomoću njih internetska mjesta
            spremaju podatke kao što su korisničko ime i lozinka za prijavu, pa
            se ne morate prijaviti svaki put kada posjetite određeno mjesto.
            Trajni kolačići ostat će na računalu više dana, mjeseci ili čak
            godina.
          </p>
          <h2>Što su to kolačići prve strane?</h2>
          <p>
            Kolačići prve strane (vlastiti kolačići) dolaze s intenretskih
            mjesta koja korisnik pregledava i mogu biti trajni ili privremeni.
            Pomoću tih kolačića internetska mjesta mogu spremiti podatke koje će
            ponovo upotrijebiti kada ih korisnik sljedeći put posjeti.
          </p>
          <h2>Što su kolačići treće strane?</h2>
          <p>
            Kolačići treće strane (drugi kolačići) potječu s drugih, partnerskih
            internet mjesta (primjerice skočnih prozora ili drugih reklama), a
            nalaze se na internetskom mjestu koje pregledavate. Pomoću tih
            kolačića internetska mjesta mogu pratiti upotrebu interneta u svrhu
            prodaje.
          </p>
          <h2>Upotrebljava li ova internetska stranica kolačiće?</h2>
          <p>
            Da, s primarnim ciljem da vam naše internet stranice omoguće
            najbolje korisničko iskustvo.
          </p>
          <h2>Kakve kolačiće upotrebljava ova insternet stranica i zašto?</h2>
          <p>
            Privremeni kolačići ili kolačići sesije (session cookies) kolačići
            su koji istječu (i automatski se brišu) kad zatvorite pretraživač.
            Mi upotrebljavamo privremene kolačiće kako bi korisnik dobio
            najbolje iskustvo pri uporabi naše stranice.
          </p>
          <p>
            Trajni ili spremljeni kolačići (persistent cookies) – njihov datum
            isteka obično je daleko u budućnosti, stoga će ostati u vašem
            pretraživaču sve dok ne isteknu ili dok ih ručno ne izbrišete. Mi
            upotrebljavamo trajne kolačiće kako bi bolje razumjeli navike naših
            korisnika, čime možemo poboljšati internetsku stranicu ovisno o
            vašim navikama. Na temelju prikupljenih podataka nije moguća
            identifikacija vas kao korisnika, a prikupljeni podatci
            upotrebljavaju se isključivo u statističke svrhe.
          </p>
          <h2>Postoje li na internetskoj stranici kolačići treće strane?</h2>
          <p>
            Postoji više vanjskih servisa koji na računalo korisnika spremaju
            ograničene kolačiće. Te kolačiće nije postavila ova internet
            stranica, ali neki služe za normalan rad određenih funkcija koje
            korisnicima olakšavaju pristup sadržaju. Stoga informacije o zaštiti
            podataka s kojima ste upoznati ovdje vrijede samo za ovu internetsku
            stranicu, a informacije o zaštiti podataka vanjskih servisa treba
            provjeriti na njihovim službenim stranicama.
          </p>
          <h2>Trenutačno omogućujemo:</h2>
          <h3>Mjerenje posjeta</h3>
          <p>
            Ova intenret stranica upotrebljava servise za mjerenje broja
            korisnika, i to Google Analytics. Na temelju tako prikupljenih
            podataka nije moguća identifikacija korisnika, a prikupljeni podatci
            upotrebljavaju se isključivo u statističke svrhe. Ako želite
            spriječiti da navedeni servisi spremaju kolačiće na vaše računalo,
            za svaki ih servis možete odbiti putem sljedećih poveznica:
          </p>
          <ul>
            <li>Google Analytics – https://tools.google.com/dlpage/gaoptout</li>
            <li>iProm – UID</li>
          </ul>
          <h2>Dodatne informacije o odbijanju kolačića</h2>
          <p>
            Trenutačno postoji više internetskih stranica za odbijanje spremanja
            kolačića za različite servise.
          </p>
          <p>Više o tome možete saznati putem sljedećih poveznica:</p>
          <ul>
            <li>http://www.allaboutcookies.org/</li>
            <li>http://www.youronlinechoices.eu/</li>
          </ul>

          <figure class="wp-block-table table-cookies">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      Cookie
                      <br />
                      name
                    </strong>
                  </td>
                  <td>
                    <strong>
                      Storage
                      <br />
                      period
                    </strong>
                  </td>
                  <td>
                    <strong>
                      Who installs
                      <br />
                      the cookie
                      <br />
                      and manages
                      <br />
                      the data it
                      <br />
                      acquires
                    </strong>
                  </td>
                  <td>
                    <strong>Domain</strong>
                  </td>
                  <td>
                    <strong>
                      Purpose of the processing of the data collected
                      <br />
                      by the cookie
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>UID</td>
                  <td>
                    36
                    <br />
                    months
                  </td>
                  <td>iPROM d.o.o.</td>
                  <td>iprom.net</td>
                  <td>
                    iPROM’s advertising cookie allows the system to
                    <br />
                    set a cookie in order to display personalized ads on
                    <br />
                    other online media after the user leaves this
                    <br />
                    website. The system installs the cookie on the user
                    <br />
                    profile (browser) based on user’s prior explicit
                    <br />
                    consent on the website. The user’s profile is
                    <br />
                    anonymous and does not contain personal
                    <br />
                    information.
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
        </div>
      </div>
    </div>
  );
}

export default CookiesHR;
