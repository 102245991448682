function CookiesSL() {
  return (
    <div className="hero hero__secondary position-relative bg-white px-2 px-sm-0">
      <div className="container narrow bg-white rounded-5 p-md-5">
        <h1 className="mb-3">Piškotki</h1>
        <div className="cookies__text-block">
          <p>
            Minimalno količino informacij (pi&scaron;kotki) je potrebno shraniti
            na va&scaron; računalnik, da bi spletna stran pravilno delovala in
            da bi se nadgrajevala in izbolj&scaron;ala va&scaron;e
            izku&scaron;nje pregledovanja. Več kot 90% spletnih strani uporablja
            prakso pi&scaron;kotkov, vendar so po pravilih Evropske unije
            obvezne zaprositi privolitev uporabnika. Z uporabo te spletne strani
            sogla&scaron;ate z uporabo pi&scaron;kotkov, v kolikor pa
            pi&scaron;kotkov ne sprejmete, lahko sicer &scaron;e naprej
            pregledujete stran, vendar se določene funkcije na tej spletni
            strani eventualno ne morejo pravilno prikazati ali izvesti.
          </p>
        </div>
        <div className="cookies__text-block ng-star-inserted">
          <div>
            <div className="title title--h5 text--primary">
              <h5>Kaj je pi&scaron;kotek?</h5>
            </div>
          </div>
          <p className="ng-star-inserted">
            Pi&scaron;kotek je informacija, ki jo na va&scaron; računalnik
            shrani spletna stran, ki ste jo obiskali. Pi&scaron;kotki običajno
            shranjujejo va&scaron;e nastavitve za spletno stran, kot so na
            primer želeni jezik ali naslov. Kasneje, ko ponovno odprete isto
            spletno stran, brskalnik po&scaron;lje nazaj pi&scaron;kotke, ki
            pripadajo tej strani. To tej strani omogoča, da prikaže informacije
            prilagojene va&scaron;im potrebam.
          </p>
          <p className="ng-star-inserted">
            Pi&scaron;kotki lahko shranjujejo &scaron;iroko paleto informacij
            vključno z osebnimi informacijami (va&scaron;e ime ali elektronsko
            po&scaron;to). Kljub vsemu pa je lahko ta informacija shranjena samo
            v primeru, če to omogočite vi &ndash; spletne strani ne morejo
            pridobiti pristopa informacijam, če ga vi niste odobrili, prav tako
            ne morejo pristopiti drugim datotekam na va&scaron;em računalniku
            ali mobilni napravi. Privzete nastavitve shranjevanja in
            po&scaron;iljanja pi&scaron;kotkov vam niso vidne, lahko pa
            spremenite va&scaron;e nastavitve brskalnika tako, da lahko sami
            izbirate, ali želite zahteve za shranjevanje pi&scaron;kotkov
            odobriti ali zavrniti, samodejno izbrisati shranjene pi&scaron;kotke
            ob zapiranju brskalnika in podobno.
          </p>
        </div>
        <div className="cookies__text-block ng-star-inserted">
          <div>
            <div className="title title--h5 text--primary">
              <h5>Kako zavrniti pi&scaron;kotke?</h5>
            </div>
          </div>
          <p className="ng-star-inserted">
            Z zavrnitvijo pi&scaron;kotkov odločate, ali želite dovoliti
            shranjevanje pi&scaron;kotkov na va&scaron;em računalniku ali
            mobilni napravi. Nastavitve pi&scaron;kotov se lahko nadzirajo in
            konfigurirajo v va&scaron;em brskalniku. Če pi&scaron;kotkov ne
            sprejmete, se lahko zgodi, da ne boste mogli uporabljati določene
            funkcije na spletni strani.
          </p>
        </div>
        <div className="cookies__text-block ng-star-inserted">
          <div>
            <div className="title title--h5 text--primary">
              <h5>Kaj so začasni pi&scaron;kotki?</h5>
            </div>
          </div>
          <p className="ng-star-inserted">
            Začasni ali sejni pi&scaron;kotki, se izbri&scaron;ejo z računalnika
            po tem, ko zaprete brskalnik. Z njihovo pomočjo spletna mesta
            shranjujejo začasne podatke, kot so na primer izdelki v nakupovalni
            ko&scaron;arici.
          </p>
        </div>
        <div className="cookies__text-block ng-star-inserted">
          <div>
            <div className="title title--h5 text--primary">
              <h5>Kaj so trajni pi&scaron;kotki?</h5>
            </div>
          </div>
          <p className="ng-star-inserted">
            Trajni ali shranjeni pi&scaron;kotki ostanejo na računalniku tudi po
            tem, ko zaprete program brskalnika. Z njihovo pomočjo spletna mesta
            shranjujejo podatke, kot so na primer naziv za prijavo in geslo,
            tako da se vam ni potrebno prijavljati vsakokrat, ko obi&scaron;čete
            določeno mesto. Trajni pi&scaron;kotki bodo ostali na računalniku
            več dni, mesecev, ali celo let.
          </p>
        </div>
        <div className="cookies__text-block ng-star-inserted">
          <div>
            <div className="title title--h5 text--primary">
              <h5>Kaj so pi&scaron;kotki prve strani?</h5>
            </div>
          </div>
          <p className="ng-star-inserted">
            Pi&scaron;kotki prve strani (lastni pi&scaron;kotki) so s spletnega
            mesta, ki si ga uporabnik ogleduje in so lahko trajni ali začasni. S
            pomočjo teh pi&scaron;kotkov spletna mesta lahko shranijo podatke,
            ki jih bodo znova uporabila, ko uporabnik naslednjič obi&scaron;če
            to mesto.
          </p>
        </div>
        <div className="cookies__text-block ng-star-inserted">
          <div>
            <div className="title title--h5 text--primary">
              <h5>Kaj so pi&scaron;kotki tretje strani?</h5>
            </div>
          </div>
          <p className="ng-star-inserted">
            Pi&scaron;kotki tretje strani (drugi pi&scaron;kotki) izvirajo iz
            drugih, partnerskih spletnih mest (kot so na primer pojavna okna ali
            druge reklame), ki se nahajajo na spletnem mestu, ki si ga
            ogledujete. S pomočjo teh pi&scaron;kotkov lahko spletna mesta
            spremljajo uporabo spleta za trženjske namene.
          </p>
        </div>
        <div className="cookies__text-block ng-star-inserted">
          <div>
            <div className="title title--h5 text--primary">
              <h5>Ali uporablja ta spletna stran pi&scaron;kotke?</h5>
            </div>
          </div>
          <p className="ng-star-inserted">
            Da, s primarnim ciljem, da bi vam na&scaron;e spletne strani
            omogočile najbolj&scaron;o uporabni&scaron;ko izku&scaron;njo.
          </p>
        </div>
        <div className="cookies__text-block ng-star-inserted">
          <div>
            <div className="title title--h5 text--primary">
              <h5>
                Kak&scaron;ne pi&scaron;kotke uporablja ta spletna stran in
                zakaj?
              </h5>
            </div>
          </div>
          <p className="ng-star-inserted">
            Začasni ali sejni pi&scaron;kotki (Session cookies) &ndash; so
            pi&scaron;kotki, ki potečejo (in se samodejno izbri&scaron;ejo), ko
            zaprete brskalnik. Mi uporabljamo začasne pi&scaron;kotke, da bi
            uporabnik dobil najbolj&scaron;o izku&scaron;njo pri uporabi
            na&scaron;e spletne strani.
          </p>
          <p className="ng-star-inserted">
            Trajni ali shranjeni pi&scaron;kotki (Persistent cookies) &ndash;
            imajo običajno datum poteka daleč v prihodnosti, zato bodo ostali v
            va&scaron;em brskalniku, dokler ne potečejo, ali dokler jih ročno ne
            izbri&scaron;ete. Mi uporabljamo trajne pi&scaron;kotke, da bi bolje
            razumeli navade uporabnikov, s čimer lahko izbolj&scaron;amo spletno
            stran glede na va&scaron;e navade. Na podlagi tako zbranih podatkov
            ni mogoča identifikacija vas kot uporabnika, zbrani podatki pa se
            uporabljajo izključno za statistične namene.
          </p>
        </div>
        <div className="cookies__text-block ng-star-inserted">
          <div>
            <div className="title title--h5 text--primary">
              <h5>
                Ali na spletni strani obstajajo pi&scaron;kotki tretje strani?
              </h5>
            </div>
          </div>
          <p className="ng-star-inserted">
            Obstaja več zunanjih servisov, ki uporabniku shranjujejo omejene
            pi&scaron;kotke. Teh pi&scaron;kotkov ni postavila ta spletna stran,
            vendar nekateri služijo za normalno delovanje določenih funkcij, ki
            uporabnikom olaj&scaron;ajo dostop k vsebini. Zaradi tega
            informacije o varstvu podatkov, s katerimi ste seznanjeni tukaj,
            veljajo samo za to spletno stran, varstvo podatkov pri zunanjih
            servisih pa je potrebno preveriti na njihovih uradnih straneh.
          </p>
        </div>
        <div className="cookies__text-block">
          <div>
            <div className="title title--h2 text--primary">
              <h2>Trenutno omogočamo:</h2>
            </div>
          </div>
          <div className="ng-star-inserted">
            <p className="text--bd">Merjenje obiskov</p>
            <p>
              Ta spletna stran uporablja servise za merjenje &scaron;tevila
              uporabnikov, in sicer Google Analytics. Na podlagi tako zbranih
              podatkov ni mogoča identifikacija uporabnikov, zbrani podatki pa
              se uporabljajo izključno za statistične namene. Če želite
              preprečiti, da vam navedeni servisi shranjujejo pi&scaron;kotke,
              jih lahko zavrnete za vsak servis na naslednjih povezavah:
            </p>
            <ul>
              <li className="ng-star-inserted">
                Google Analytics -{" "}
                <a
                  className="link"
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank">
                  https://tools.google.com/dlpage/gaoptout
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="cookies__text-block">
          <div>
            <div className="title title--h2 text--primary">
              <h2>Dodatne informacije o zavrnitvi pi&scaron;kotkov</h2>
            </div>
          </div>
          <p>
            Trenutno obstaja več spletnih strani za zavrnitev shranjevanja
            pi&scaron;kotkov za različne servise.
          </p>
          <p>Več lahko izveste na naslednjih povezavah:</p>
          <ul>
            <li className="ng-star-inserted">
              <a
                className="link"
                href="http://www.allaboutcookies.org"
                target="_blank">
                http://www.allaboutcookies.org
              </a>
            </li>
            <li className="ng-star-inserted">
              <a
                className="link"
                href="http://www.youronlinechoices.eu/"
                target="_blank">
                http://www.youronlinechoices.eu/
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CookiesSL;
