import { useTranslation } from "react-i18next";

const Usps = ({ images }) => {
  const { t } = useTranslation();

  return (
    <div className="text-center my-4 my-sm-6 usps" id="ustvari">
      <div className="container">
        <div className="row ">
          <div className="col-6 col-sm-3 text-center">
            <div className="mb-3">
              <img src={images["usp-01.png"]} />
            </div>

            <p
              className=""
              dangerouslySetInnerHTML={{ __html: t("HowUSP1") }}
            />
          </div>
          <div className="col-6 col-sm-3 text-center">
            <div className="mb-3">
              <img src={images["usp-02.png"]} />
            </div>
            <p
              className=""
              dangerouslySetInnerHTML={{ __html: t("HowUSP2") }}
            />
          </div>
          <div className="col-6 col-sm-3 text-center">
            <div className="mb-3">
              <img src={images["usp-03.png"]} />
            </div>
            <p
              className=""
              dangerouslySetInnerHTML={{ __html: t("HowUSP3") }}
            />
          </div>
          <div className="col-6 col-sm-3 text-center">
            <div className="mb-3">
              <img src={images["usp-04.png"]} />
            </div>
            <p
              className=""
              dangerouslySetInnerHTML={{ __html: t("HowUSP4") }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usps;
