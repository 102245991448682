import { useTranslation } from "react-i18next";

import Header from "./Header";

const Howitworks = ({ images, trackEvent }) => {
  const { t } = useTranslation();

  return (
    <div
      className="container my-6 text-center"
      id="kakodeluje"
      style={{ zIndex: 4, position: "relative" }}>
      <div className="howitworks mt-6">
        <div className="headline">
          <h2>{t("HowItWorksH")}</h2>
          <p>{t("HowItWorksP")}</p>
        </div>

        <div className="row g-3 pt-4 d-flex align-items-center justify-content-center text-start">
          <div className="col-md-4">
            <img className="w-100" src={images["junior-kakodeluje.png"]} />
          </div>

          <div className="col-md-7">
            <h3>{t("HowItWorksS1H")}</h3>
            <p className="mb-4">{t("HowItWorksS1P")}</p>

            <h3>{t("HowItWorksS2H")}</h3>
            <p className="mb-4">{t("HowItWorksS2P")}</p>

            <h3>{t("HowItWorksS3H")}</h3>
            <p className="mb-4">{t("HowItWorksS3P")}</p>

            <h3>{t("HowItWorksS4H")}</h3>
            <p className="mb-4">{t("HowItWorksS4P")}</p>

            <a href="#ustvari">
              <button
                onClick={() =>
                  trackEvent(
                    "user_interaction",
                    "click",
                    "jumpto_create",
                    "howto"
                  )
                }
                className="btn btn-yellow mt-3 py-3 px-4 text-uppercase text-white">
                {t("HeroCTA")}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Howitworks;
