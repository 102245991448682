import { useState, useEffect, useRef } from "react";
import axios from "axios";
import io from "socket.io-client";
import { Rating } from "react-simple-star-rating";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { RWebShare } from "react-web-share";
import { trackEvent } from "views/Home/tracking";

import { importAll } from "utils/importImages";

import Header from "views/Home/Header";
import Footer from "./views/Home/Footer";
import Remember from "views/Home/Remember";
import Dogodivscine from "views/Home/Dogodivscine";
import Loader from "views/Home/Loader";

// Styles
import "./assets/styles/style.scss";

function Result({ baseDomain }) {
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [optin, setOptin] = useState(false);
  const [isErrorOptin, setIsErrorOptin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [txtToSpeechLoading, setTxtToSpeechLoading] = useState(false);
  const [txtToSpeechLink, setTxtToSpeechLink] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [rating, setRating] = useState(null);
  const [copy, setCopy] = useState({
    value: "",
    copied: false,
  });
  const [partialResponse, setPartialResponse] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);

  const storyID = useSelector((state) => state.story.id);

  const URL =
    process.env.NODE_ENV === "production" ? undefined : "http://localhost:4000";

  let { userId } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const recaptchaRef = useRef(null);

  const images = importAll(
    require.context("./assets/images/_lunaai", false, /\.(png|jpe?g|svg)$/)
  );

  const sendEmail = async () => {
    if (optin) {
      setLoading(true);
      setIsErrorOptin(false);
      const captchaToken =
        localStorage.getItem("captcha") === "success"
          ? "success"
          : await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      axios
        .post(`${baseDomain}/sendEmail`, {
          tomail: email,
          result: data.result,
          firstname: data.firstname,
          author: data.author,
          id: userId,
          captchaToken,
        })
        .then((response) => {
          if (response.data.msg === "success") {
            setLoading(false);
            setIsSent(true);
            setIsError(false);
            setEmail("");
          } else if (response.data.msg === "fail") {
            setIsError(true);
            setLoading(false);
            setIsSent(false);
            setEmail("");
          }
        });
    } else {
      setIsErrorOptin(true);
    }
  };

  const txtToSpeech = async () => {
    setTxtToSpeechLoading(true);

    axios
      .post(`${baseDomain}/txtToSpeech`, {
        result: data.result,
      })
      .then((response) => {
        if (response.data.message === "success") {
          setTxtToSpeechLoading(false);
          setTxtToSpeechLink(response.data.path);
        } else if (response.data.message === "fail") {
          setTxtToSpeechLoading(false);
        }
      });
  };

  console.log(data);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    axios
      .post(`${baseDomain}/rate`, {
        jobId: data.jobId,
        rating: rate,
      })
      .then((response) => {
        localStorage.setItem(`rated-${data.jobId}`, true);
        trackEvent("user_interaction", "select", "stars", rate);
      });

    // other logic
  };

  const onShare = () => {
    const title = t("SocialH");
    const url = t("SocialI");
    const text = t("SocialP");
    if (navigator.share) {
      navigator
        .share({ title: `${title}`, url: `${url}`, text: `${text}` })
        .then(() => {
          //console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      window.open(
        `https://www.facebook.com/sharer.php?u=${url.trim()}&quote=${text}`,
        "_blank"
      );
    }
    trackEvent("user_interaction", "click", "social_share", "");
  }; //onShare ends here

  const copiedText = () => {
    setCopy({ copied: true });
  };

  const tryAgain = () => {
    navigate("/");
    trackEvent("user_interaction", "click", "create_new_story", "");
  };

  useEffect(() => {
    const socket = io(URL);

    const getResponse = async () => {
      try {
        const res = await axios.get(`${baseDomain}/getResult/${userId}`);

        setData(res.data);
        setCopy({ value: res.data.result.replace(/<\/?[^>]+(>|$)/g, "") });
      } catch (err) {
        console.log("error", err);
      }
    };

    socket.on("job progress", (data) => {
      if (userId === data.jobId) {
        setPartialResponse(data.partial.text);
      }
    });

    socket.on("job completed", (data) => {
      if (userId === data.jobId) {
        trackEvent("completion", "click", "story_created", "main");

        setTimeout(() => {
          getResponse();
        }, 300);
      }
    });

    getResponse();

    // Clean up the WebSocket connection
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}>
      <Header trackEvent={trackEvent} images={images} />
      <div className="hero hero__secondary position-relative bg-white px-2 px-sm-0">
        <div className="container narrow bg-white rounded-5">
          <div className="pt-3 pt-md-5 px-3 px-md-6 mb-4">
            <div className="story">
              {data === null && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ delay: 1, duration: 2 }}
                  dangerouslySetInnerHTML={{ __html: partialResponse }}
                />
              )}

              {data !== null && (
                <>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 2 }}
                    dangerouslySetInnerHTML={{
                      __html: data.result,
                    }}
                  />
                  <motion.img
                    className="w-100"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 2 }}
                    src={`/images/${data.image}.jpg`}
                  />
                </>
              )}
            </div>

            <div className="row mt-5">
              <div className="col-sm-8 text-center text-sm-start">
                <CopyToClipboard text={copy.value} onCopy={() => copiedText()}>
                  <button
                    className={`btn btn-sm text-white  px-3 py-3 transform-none ${
                      copy.copied && "pe-none"
                    }`}>
                    {copy.copied ? `✅ ${t("Copied")}` : `📝 ${t("Copy")}`}
                  </button>
                </CopyToClipboard>

                <button
                  onClick={() => tryAgain()}
                  className="btn btn-sm text-white  px-3 py-3 transform-none ms-1 ms-sm-3">
                  🔁 {t("Tagain")}
                </button>

                {localStorage.getItem("isAdmin") && (
                  <>
                    {txtToSpeechLink === "" && (
                      <button
                        onClick={() => txtToSpeech()}
                        className="btn btn-sm text-white  px-3 py-3 transform-none ms-1 ms-sm-3">
                        🎵 {txtToSpeechLoading ? "Loading" : "TXT2SPEECH"}
                      </button>
                    )}

                    {txtToSpeechLink !== "" && (
                      <a
                        className="btn btn-sm text-white  px-3 py-3 transform-none ms-1 ms-sm-3"
                        download=""
                        target="_blank"
                        href={`https://zgodbarcek.junior-adventures.com/mp3/${txtToSpeechLink}`}>
                        🎵 Download
                      </a>
                    )}
                  </>
                )}
              </div>
              <div className="col-sm-4 text-center text-sm-end mt-3 mt-sm-0">
                <button
                  onClick={() => onShare()}
                  className="btn btn-sm text-white px-3 py-3 transform-none">
                  🥰 {t("Share")}
                </button>
              </div>
            </div>

            <div className="position-relative">
              <div className="mt-5 px-md-6 py-4">
                {isSent && (
                  <div className="message d-flex align-items-center text-center justify-content-center">
                    <div>
                      <p
                        className="text-blue"
                        dangerouslySetInnerHTML={{ __html: t("EmailOnTheWay") }}
                      />
                      <motion.img
                        key={isSent}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                        transition={{
                          duration: 0.5,
                        }}
                        className="sent"
                        src={images["icon-sent.png"]}
                      />
                    </div>
                  </div>
                )}

                {isError && (
                  <div className="message d-flex align-items-center text-center justify-content-center">
                    <div>
                      <h3 className="">{t("EmailError")}</h3>

                      <button
                        onClick={() => setIsError(false)}
                        className="btn btn-orange text-white fw-bold  px-3"
                        type="button">
                        <span>{t("Tagain")}</span>
                      </button>
                    </div>
                  </div>
                )}

                <h3 className="text-blue text-center">{t("SendH")} 🎂</h3>
                <p className="text-center">{t("SendP")}</p>

                <div className="row g-2">
                  <div className="col-8">
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder={t("SendEmail")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-4">
                    <button
                      onClick={() => sendEmail()}
                      className="btn btn-orange text-white   px-3 ms-2 transform-none w-100 h-100"
                      type="button"
                      disabled={loading && "disabled"}>
                      {loading && (
                        <span
                          className="spinner-border spinner-border-md me-3"
                          role="status"
                          aria-hidden="true"></span>
                      )}
                      <span>{t("Send")}</span>
                    </button>
                  </div>
                  <div className="col-12 col-md-8 mt-4 mt-md-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="agreement"
                        value={optin}
                        checked={optin === true}
                        onChange={(e) => setOptin(!optin)}
                      />
                      <label
                        className="form-check-label xsmall"
                        htmlFor="agreement"
                        dangerouslySetInnerHTML={{
                          __html: t("SendOptin"),
                        }}
                      />
                    </div>
                    {isErrorOptin && !optin && (
                      <p className="text-orange small fw-bold">
                        {t("acceptPrivacy")}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mt-5 px-md-7">
            <h3>{t("Rating")}</h3>
            <p>{t("RatingP")}</p>
            <div className="row d-flex align-items-center ">
              <div className="col-12 mb-0 position-relative">
                <Rating
                  emptyIcon={
                    <svg
                      width="27"
                      height="25"
                      viewBox="0 0 27 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.9741 1.56533L16.0257 7.69374C16.3669 8.71304 17.3215 9.40012 18.3964 9.40012H24.938C25.426 9.40012 25.6249 10.0275 25.2261 10.3087L20.0117 13.9858C19.1092 14.6222 18.7312 15.7754 19.0817 16.8226L21.0915 22.8262C21.2449 23.2842 20.724 23.6719 20.3293 23.3935L14.9407 19.5937C14.0768 18.9845 12.9232 18.9845 12.0593 19.5937L6.67075 23.3935C6.27599 23.6719 5.75512 23.2842 5.90846 22.8262L7.91826 16.8226C8.26882 15.7754 7.8908 14.6222 6.98832 13.9858L1.77385 10.3087C1.37506 10.0275 1.57404 9.40012 2.062 9.40012H8.60359C9.67848 9.40012 10.6331 8.71304 10.9743 7.69375L13.0259 1.56533C13.1782 1.11029 13.8218 1.1103 13.9741 1.56533Z"
                        stroke="#F2B000"
                        strokeWidth="2"
                      />
                    </svg>
                  }
                  fillIcon={
                    <svg
                      width="27"
                      height="25"
                      viewBox="0 0 27 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.0776 1.24788C12.5346 -0.117217 14.4654 -0.117221 14.9224 1.24788L16.974 7.3763C17.1787 7.98787 17.7515 8.40012 18.3964 8.40012H24.938C26.4019 8.40012 26.9988 10.2823 25.8024 11.126L20.588 14.8031C20.0465 15.1849 19.8197 15.8768 20.03 16.5051L22.0398 22.5087C22.4998 23.8828 20.9372 25.0459 19.753 24.2108L14.3644 20.4109C13.8461 20.0454 13.1539 20.0454 12.6356 20.4109L7.24704 24.2108C6.0628 25.0459 4.50017 23.8829 4.96018 22.5087L6.96999 16.5051C7.18032 15.8768 6.95351 15.1849 6.41202 14.8031L1.19756 11.126C0.00119424 10.2823 0.598094 8.40012 2.062 8.40012H8.60359C9.24853 8.40012 9.82127 7.98787 10.026 7.3763L12.0776 1.24788Z"
                        fill="#F2B000"
                      />
                    </svg>
                  }
                  customIcons={[{ test: "test" }]}
                  onClick={handleRating}
                  readonly={
                    data !== null &&
                    (localStorage.getItem(`rated-${data.jobId}`) == "true" ||
                      rating !== null)
                  }
                />
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                  badge="inline"
                />
                {rating !== null && (
                  <div
                    style={{ top: "60px" }}
                    className="position-absolute start-50 translate-middle">
                    <h3 className="mb-0">{t("RatingC")}</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Remember images={images} t={t} />
      <Dogodivscine images={images} t={t} />

      {/*       {loading && <Loader images={images} type="fetching" t={t} />}
      {error && (
        <Loader
          images={images}
          type="error"
          error={error}
          setError={setError}
          t={t}
        />
      )} */}
    </motion.div>
  );
}

export default Result;
