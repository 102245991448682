const Dogodivscine = ({ images, t, trackEvent, currentLanguage }) => (
  <div className="dogodivscine">
    <div className="container narrow my-6 position-relative text-center ">
      {currentLanguage === "sl" && (
        <img src={images["logo-junior-dogodivscine.svg"]} />
      )}
      {currentLanguage === "hr" && (
        <img src={images["logo-junior-dogodivscine-hr.svg"]} />
      )}
      {currentLanguage === "rs" && (
        <img src={images["logo-junior-dogodivscine-rs.svg"]} />
      )}
      <p className="nunito headline lead mt-4">{t("AdventureP")}</p>
      <a
        className="btn btn-yellow mt-5 py-3 px-4 text-uppercase text-white"
        onClick={() =>
          trackEvent("user_interaction", "click", "junior_adventures", "footer")
        }
        href={t("AdventureLT")}
        target="_blank">
        {t("AdventureLP")}
      </a>
    </div>
  </div>
);

export default Dogodivscine;
