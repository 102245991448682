const Remember = ({ images, t }) => (
  <div className="remember">
    <div className="container narrow my-6 position-relative ">
      <div className=" mt-6">
        <div className="row g-3 pt-4 d-flex align-items-center  text-start">
          <div className="col-md-7">
            <h3>{t("RememberH")}</h3>
            <p className="mb-4">{t("RememberP1")}</p>
            <p>{t("RememberP2")}</p>
          </div>
        </div>
      </div>
      <img src={images["junior-mreza.png"]} className="d-none d-md-block" />
      <img
        src={images["junior-mreza-mobile.png"]}
        className="d-block d-md-none"
      />
    </div>
  </div>
);

export default Remember;
