const dataExclude = [
  "Puši",
  "Kurac",
  "Kurec",
  "Kurc",
  "Kurčevo",
  "Fafanje",
  "Fafati",
  "Skurcati",
  "Sranje",
  "Zasral",
  "Posral",
  "Drekač",
  "Drek",
  "Seronja",
  "serji",
  "seri",
  "Anal",
  "Analno",
  "Sodomija",
  "Pornič",
  "Porno",
  "Pornografija",
  "Pizda",
  "Pizdun",
  "Pezde",
  "Popizdi",
  "Napizdi",
  "Razpizdi",
  "Dopizdi",
  "Odpizdi",
  "Pizdarija",
  "Pizdin",
  "Pička",
  "Pičkin",
  "Prasica",
  "Prasec",
  "Prase",
  "Kurba",
  "Kurbin sin",
  "Kurbir",
  "Kurbišče",
  "Kurbarstvo",
  "Kurbarija",
  "Kurva",
  "Jebem ti",
  "Jebi se",
  "Jeba",
  "Jebač",
  "Jebiveter",
  "Jebanje",
  "Jebeno",
  "Jebati",
  "Odjebati",
  "Najebati",
  "Zajebati",
  "Zjeban",
  "Šlatanje",
  "Šlatati",
  "Črnuh",
  "Čefur",
  "Limka",
  "Gaser",
  "Peder",
  "Debil",
  "Idiot",
  "Kreten",
  "Fak",
  "Fukati",
  "Fukanje",
  "Pofukan",
  "Prfuknjen",
  "Fuknjen",
  "Seks",
  "Seksi",
  "Seksanje",
  "Seksati",
  "Alkohol",
  "Droge",
  "Vino",
  "Pivo",
  "Viski",
  "Vodka",
  "Gin",
  "Rum",
  "Liker",
  "Tekila",
  "Marihuana",
  "LSD",
  "Cid",
  "Psilocibin",
  "Ketamin",
  "Psihadeliki",
  "Halucinogen",
  "Ekstazi",
  "MDMA",
  "Speed",
  "spid",
  "Heroin",
  "Kokain",
  "Snifanje",
  "Dildo",
  "Vibrator",
  "Otipavanje",
  "Otipavati",
  "Kurac",
  "Puši",
  "Puši kurac",
  "Kurac ",
  "Kurac ",
  "Kurčevo ",
  "Pušenje",
  "Pušiti ",
  "Sjebati ",
  "Sranje",
  "Zasrao",
  "Posrao ",
  "Strahopizdec",
  "Govno ",
  "Seronja",
  "Ne seri",
  "Čmar",
  "Analno",
  "Sodomija ",
  "Pornić",
  "Porno",
  "Pornografija ",
  "Pizda",
  "Pizdun",
  "Pičke",
  "Popizdi",
  "Napizdi",
  "Raspizdi",
  "Dopizdi",
  "Odjebi ",
  "Pizdarija",
  "Pizdin ",
  "Pička",
  "Pičkin ",
  "Svinja",
  "Prasac ",
  "Prase ",
  "Kurva",
  "Kurvin sin",
  "Kurviš",
  "Kupleraj",
  "Kurveraj",
  "Kurvanje",
  "Kurva ",
  "Jebem ti",
  "Jebi se",
  "Ševa ",
  "Jebač",
  "Jebivjetar",
  "Jebanje",
  "Jebeno ",
  "Jebati ",
  "Odjebati ",
  "Najebati ",
  "Zajebati ",
  "Sjeban ",
  "Šlatanje",
  "Šlatati",
  "Crnac ",
  "Jugoslaven ",
  "Gaserka",
  "Gaser ",
  "Peder ",
  "Debil",
  "Idiot",
  "Kreten ",
  "Fuck ",
  "Fukati",
  "Fukanje",
  "Pofukan ",
  "Sjeban ",
  "Ujeban ",
  "Seks",
  "Seksi ",
  "Seksanje",
  "Seksati",
  "Alkohol",
  "Droge",
  "Vino",
  "Pivo",
  "Viski",
  "Votka",
  "Džin",
  "Rum",
  "Liker",
  "Tekila ",
  "Marihuana",
  "LSD",
  "Cid",
  "Psilocibin ",
  "Ketamin",
  "Psihodelici",
  "Halucinogen",
  "Ecstasy",
  "MDMA",
  "Speed",
  "Heroin",
  "Kokain",
  "Snifanje",
  "Dildo",
  "Vibrator",
  "Pipkanje ",
  "Pipkati ",
  "cigarete",
  "govno",
  "Ukrajina",
  "Javelin",
  "dron Bayraktar",
  "mesar",
  "salamoreznica",
  "ubojica",
  "rasparano lice",
  "droge",
  "proljev",
  "prdac",
  "pištolj",
  "WC školjka",
  "Vape",
  "Penis",
  "prsa",
];

export { dataExclude };
